/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:1e776c5e-8199-49cb-981b-ab3ef2a405fc",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_1faFLdcj6",
    "aws_user_pools_web_client_id": "5fl1g9hu5d254get4vo24mf2ml",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_mobile_analytics_app_id": "235629c195b94852bbe2d8ee0e70eafc",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
