import React, { useState } from "react";
// import Stack from '@mui/material/Stack';
// import Button from '@mui/material/Button';
// import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
import BasicSelect from "../Components/BasicSelect";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useAxiosGet } from '../Hooks/HttpRequests';
import MuiTable from '../Components/MuiTable';
import { smdTeams } from "../data/teamMap";
import { Overview } from "../Components/ReactApexChart";
import { LineChart, BarChart, TreeMap, ApexHeatMap, Sample } from "../Components/ReactApexChart";



// import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2

export default function Team() {

  const [value, setValue] = useState("overview");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="mb-3">
      {/* <h1 className="font-bold text-2xl">This is the Team page</h1> */}
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        textColor="primary"
      >
        <Tab value="overview" label="Overview" />
        <Tab value="stats" label="Team Stats" />
        <Tab value="h2h" label="H2H" />
        <Tab value="week" label="Weekly Results"/>
        <Tab value="scoring" label="Scoring Avgs" />
        <Tab value="time" label="Time Machine" />
      </Tabs>
      <Divider />
    <div>
      {value === "overview" && <Overview /> }
      {value === "stats" && <TeamStats /> }
      {value === "h2h" && <H2H />}
      {value === "week" && <Results />}

      {value === "scoring" && <Scoring />}
      {value === "time" && <TimeMachine />}
    </div>
    </div>
  );
}


function TeamStats() {
  const [val, setVal] = useState("start");

  const handleButton = (event, newValue) => {
    setVal(newValue);
  };

  return (
    <div className="mb-3">
      {/* <h2>Team Statistics</h2>
      <p>All Time Record, Record by Season,  Most Points, Least Points</p> */}
      <Tabs
        value={val}
        onChange={handleButton}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        textColor="secondary"
      >
        {/* <Tab value="all" label="All Time Stats" />
        <Tab value="season" label="Season Stats" /> */}
        {/* <Tab value="results" label="Weekly Results" /> */}
        <Tab value="start" label="Starting %" />

      </Tabs>

      <div>
        {val === "all" && <All /> }
        {val === "season" && <Season />}
        {val === "records" && <Records />}
        {/* {val === "results" && <Results />} */}
        {val === "start" && <Start />}
      </div>
    </div>
  );
}

function TimeMachine() {
  const [year, setYear] = useState("2023");
  const [week, setWeek] = useState(1);
  const [team, setTeam] = useState(1);
  const [teamName, setTeamName] = useState('caseyott');


  const yearOptions = ['2023', '2022', '2021', '2020']
  const weekOptions = Array.from({length: 18}, (_, i) => i + 1)
  const teamOptions = Array.from({length: 12}, (_, i) => i + 1)
  const teams = smdTeams

  const handleTeam = (event) => {
    setTeam(event.target.value);
    for (var i=0; i < smdTeams.length; i++) {
      if (smdTeams[i]['roster_id'] == event.target.value) {
        setTeamName(smdTeams[i]['username']);
        // console.log(url)
      }
    }
  };

  const handleYear  = (event) => {
    setYear(event.target.value);
    console.log(`year=${event.target.value}`)
  };

  const handleWeek = (event) => {
    setWeek(event.target.value);
    console.log(`week=${event.target.value}`)
  };

  const yearMenuItems = []
  for (let i = 0; i < yearOptions.length; i++) {
    yearMenuItems.push(<MenuItem key={yearOptions[i]} value={yearOptions[i]}>{yearOptions[i]}</MenuItem>);
  }

  const weekMenuItems = []
  for (let i = 0; i < weekOptions.length; i++) {
    // note: we are adding a key prop here to allow react to uniquely identify each
    // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
    weekMenuItems.push(<MenuItem key={weekOptions[i]} value={weekOptions[i]}>{weekOptions[i]}</MenuItem>);
  }

  const teamMenuItems = []
  for (let i = 0; i < teamOptions.length; i++) {
    teamMenuItems.push(<MenuItem key={teams[i]['roster_id']} value={teams[i]['roster_id']}>{teams[i]['username']}</MenuItem>);
  }

  return (
    <div>
      <Box >
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small" >
            <InputLabel id={'team'}>{'Team'}</InputLabel>
            <Select
            labelId={'team'}
            id={'team'}
            value={team}
            label={'Team'}
            onChange={handleTeam}
            >
            {teamMenuItems}
            </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small" >
            <InputLabel id={'year'}>{'Year'}</InputLabel>
            <Select
            labelId={'year'}
            id={'year'}
            value={year}
            label={'Year'}
            onChange={handleYear}
            >
            {yearMenuItems}
            </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small" >
            <InputLabel id={'week'}>{'Week'}</InputLabel>
            <Select
            labelId={'week'}
            id={'Week'}
            value={week}
            label={'Week'}
            onChange={handleWeek}
            >
            {weekMenuItems}
            </Select>
        </FormControl>
        </Box>
      {<Roster year={year} week={week} teamName={teamName}/>}
    </div>
  );
}

function Roster(props) {

  const week = 2 //TODO: update to current week
  const year = 2023

  const url = `https://supermegadynasty.com/smd/weekly_rosters/${props.year}/${props.teamName}/week_${props.week}.json`
  const current_url = `https://supermegadynasty.com/smd/weekly_rosters/${year}/${props.teamName}/week_${week}.json`

  console.log(url)

  const headCells = [
    // {
    //   id: 'id',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'ID',
    // },
    {
      id: 'position',
      numeric: false,
      disablePadding: false,
      label: 'Position',
    },
    {
      id: 'full_name',
      numeric: true,
      disablePadding: false,
      label: 'Player',
    },
    {
      id: 'start_pos',
      numeric: true,
      disablePadding: false,
      label: 'Starting Pos.',
    },
    {
      id: 'points',
      numeric: true,
      disablePadding: false,
      label: 'Points',
    }
  ];

  const title = `${props.teamName} ${props.year} Week: ${props.week}`
  const ctitle = `${props.teamName} ${year} Week: ${week} *static*`

  return (
    <div className="md:flex flex-wrap">
      <div className="pr-2 max-w-prose">
        {/* {product.data.player_map.map(item => <div>{item.full_name}</div>)} */}
        <MuiTable table='rosters' headCells={headCells} url={url} title={title} page={30}/>
      </div>
      <div className="pr-2 max-w-prose">
      {/* {product.data.player_map.map(item => <div>{item.full_name}</div>)} */}
      <MuiTable table='rosters' headCells={headCells} url={current_url} title={ctitle} page={30}/>
    </div>
  </div>
  );
}


function ResultRoster(props) {

  const year = 2023

  const url         = `https://supermegadynasty.com/smd/team_results/${props.year}/${props.teamName}.json`
  // const current_url = `https://supermegadynasty.com/smd/team_results/${year}/${props.teamName}.json`

  console.log(url)

  const headCells = [
    {
      id: 'week',
      numeric: true,
      disablePadding: false,
      label: 'Week',
    },
    {
      id: 'opp_name',
      numeric: false,
      disablePadding: false,
      label: 'VS',
    },
    {
      id: 'team_score',
      numeric: false,
      disablePadding: false,
      label: 'Score',
    },
    {
      id: 'opp_score',
      numeric: true,
      disablePadding: false,
      label: 'Opp. Score',
    },
    {
      id: 'result',
      numeric: true,
      disablePadding: false,
      label: 'W/L',
    },
    {
      id: 'score_diff',
      numeric: true,
      disablePadding: false,
      label: 'Score +/-',
    },
    {
      id: 'median_score',
      numeric: true,
      disablePadding: false,
      label: 'Median',
    },
    {
      id: 'median_result',
      numeric: true,
      disablePadding: false,
      label: 'Median W/L',
    },
    {
      id: 'median_diff',
      numeric: true,
      disablePadding: false,
      label: 'Median +/-',
    },
    {
      id: 'score_rank',
      numeric: true,
      disablePadding: false,
      label: 'Scoring Rank',
    },
  ];

  const title = `${props.teamName} ${props.year} Weekly Results`

  return (
    <div className="md:flex flex-wrap">
      {/* <div className="pr-2 max-w-prose"> */}
        {/* {product.data.player_map.map(item => <div>{item.full_name}</div>)} */}
        <MuiTable table='start' headCells={headCells} url={url} title={title} page={30}/>
      {/* </div> */}
  </div>
  );
}

function Start(props) {

  // const url = `https://supermegadynasty.com/smd_1_2023_start.json`

  const headCells = [
    {
      id: 'rostered_weeks',
      numeric: true,
      disablePadding: false,
      label: 'Roster Wks',
    },
    {
      id: 'full_name',
      numeric: false,
      disablePadding: false,
      label: 'Player',
    },
    {
      id: 'position',
      numeric: false,
      disablePadding: false,
      label: 'Pos',
    },
    {
      id: 'starts',
      numeric: true,
      disablePadding: false,
      label: 'Starts',
    },
    {
      id: 'start_pct',
      numeric: true,
      disablePadding: false,
      label: 'Start %',
    },
    {
      id: 'total_points',
      numeric: true,
      disablePadding: false,
      label: 'Points',
    },
    {
      id: 'start_points',
      numeric: true,
      disablePadding: false,
      label: 'Starting Points',
    },
    {
      id: 'points_per_week',
      numeric: true,
      disablePadding: false,
      label: 'Pts/Wk',
    },
    {
      id: 'points_per_start',
      numeric: true,
      disablePadding: false,
      label: 'Pts/Strt',
    },
    {
      id: 'bench_points',
      numeric: true,
      disablePadding: false,
      label: 'Bench Pts',
    },
    {
      id: 'point_util',
      numeric: true,
      disablePadding: false,
      label: 'Pts Util%',
    }
  ];

  console.log('TEAM STARTING % PAGE')

  const title = `Team Starting %`
  const teamOptions = Array.from({length: 12}, (_, i) => i + 1)
  const teams = smdTeams
  const [team, setTeam] = useState(1);
  const [teamName, setTeamName] = useState('caseyott');
  const yearOptions = ['2023', '2022', '2021', '2020', 'all_years']
  const [year, setYear] = useState("2023");


  const [url, setUrl] = useState(`https://supermegadynasty.com/smd/reports/starting_pct/2023/${teamName}.json`)

  const handleTeam = (event) => {
    setTeam(event.target.value);
    for (var i=0; i < smdTeams.length; i++) {
      if (smdTeams[i]['roster_id'] == event.target.value) {
        setTeamName(smdTeams[i]['username']);
        setUrl(`https://supermegadynasty.com/smd/reports/starting_pct/${year}/${smdTeams[i]['username']}.json`)
        // console.log(url)
      }
    }
  };

  const handleYear  = (event) => {
    setYear(event.target.value);
    console.log(`year=${event.target.value}`)
    setUrl(`https://supermegadynasty.com/smd/reports/starting_pct/${event.target.value}/${teamName}.json`)
    // console.log(url)

  };

  const teamMenuItems = []
  for (let i = 0; i < teamOptions.length; i++) {
    teamMenuItems.push(<MenuItem key={teams[i]['roster_id']} value={teams[i]['roster_id']}>{teams[i]['username']}</MenuItem>);
  }

  const yearMenuItems = []
  for (let i = 0; i < yearOptions.length; i++) {
    yearMenuItems.push(<MenuItem key={yearOptions[i]} value={yearOptions[i]}>{yearOptions[i]}</MenuItem>);
  }

  return (
    <div className="md:flex flex-wrap">
      <div className="">
        <Box >
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small" >
            <InputLabel id={'team'}>{'Team'}</InputLabel>
            <Select
            labelId={'team'}
            id={'team'}
            value={team}
            label={'Team'}
            onChange={handleTeam}
            >
            {teamMenuItems}
            </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small" >
            <InputLabel id={'year'}>{'Year'}</InputLabel>
            <Select
            labelId={'year'}
            id={'year'}
            value={year}
            label={'Year'}
            onChange={handleYear}
            >
            {yearMenuItems}
            </Select>
        </FormControl>
        </Box >
        <MuiTable table='start' headCells={headCells} url={url} title={title} page={50}/>
      </div>
  </div>
  );
}

function Results(props) {

  // const url = `https://supermegadynasty.com/smd_1_2023_start.json`

  const headCells = [
    {
      id: 'week',
      numeric: true,
      disablePadding: false,
      label: 'Week',
    },
    {
      id: 'opponent',
      numeric: false,
      disablePadding: false,
      label: 'VS',
    },
    {
      id: 'team_score',
      numeric: false,
      disablePadding: false,
      label: 'Score',
    },
    {
      id: 'opp_score',
      numeric: true,
      disablePadding: false,
      label: 'Opp. Score',
    },
    {
      id: 'result',
      numeric: true,
      disablePadding: false,
      label: 'W/L',
    },
    {
      id: 'score_diff',
      numeric: true,
      disablePadding: false,
      label: 'Score +/-',
    },
    {
      id: 'median_score',
      numeric: true,
      disablePadding: false,
      label: 'Median',
    },
    {
      id: 'median_result',
      numeric: true,
      disablePadding: false,
      label: 'Median W/L',
    },
    {
      id: 'median_diff',
      numeric: true,
      disablePadding: false,
      label: 'Median +/-',
    },
  ];

  const title = `Weekly Results`

  const [year, setYear] = useState('2023');
  const [week, setWeek] = useState(1);
  const [team, setTeam] = useState(1);
  const [teamName, setTeamName] = useState('caseyott');


  const yearOptions = ['2023', '2022', '2021', '2020']
  const teamOptions = Array.from({length: 12}, (_, i) => i + 1)
  const teams = smdTeams

  const handleTeam = (event) => {
    setTeam(event.target.value);
    for (var i=0; i < smdTeams.length; i++) {
      if (smdTeams[i]['roster_id'] == event.target.value) {
        setTeamName(smdTeams[i]['username']);
        // setUrl(`https://supermegadynasty.com/smd/team_results/${year}/${teamName}.json`)
        // console.log(url)
      }
    }
  };

  const handleYear  = (event) => {
    for (var i=0; i < yearOptions.length; i++) {
      if (yearOptions[i] == event.target.value) {
        setYear(event.target.value);
        console.log(`year=${event.target.value}`)
        // setUrl(`https://supermegadynasty.com/smd/team_results/${year}/${teamName}.json`)
        // console.log(url)
      }
    }
  };


  const [url, setUrl] = useState(`https://supermegadynasty.com/smd/team_results/${year}/${teamName}.json`)

  const teamMenuItems = []
  for (let i = 0; i < teamOptions.length; i++) {
    teamMenuItems.push(<MenuItem key={teams[i]['roster_id']} value={teams[i]['roster_id']}>{teams[i]['username']}</MenuItem>);
  }

  const yearMenuItems = []
  for (let i = 0; i < yearOptions.length; i++) {
    yearMenuItems.push(<MenuItem key={yearOptions[i]} value={yearOptions[i]}>{yearOptions[i]}</MenuItem>);
  }

  return (
    <div className="md:flex flex-wrap">
      <div className="">
        <Box >
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small" >
            <InputLabel id={'team'}>{'Team'}</InputLabel>
            <Select
            labelId={'team'}
            id={'team'}
            value={team}
            label={'Team'}
            onChange={handleTeam}
            >
            {teamMenuItems}
            </Select>
        </FormControl>
        <FormControl>
        <InputLabel id={'year'}>{'Year'}</InputLabel>
        <Select
            labelId={'year'}
            id={'year'}
            value={year}
            label={'Year'}
            onChange={handleYear}
            >
            {yearMenuItems}
            </Select>
        </FormControl>
        </Box >
        {<ResultRoster year={year} week={week} teamName={teamName}/>}
      </div>
  </div>
  );
}


function All() {
  return (
    <div className="p-3">
      <h2>All Time Stats</h2>
    </div>
  );
}

function Season() {
  return (
    <div className="p-3">
      <h2>Season Stats</h2>
    </div>
  );
}

function Records() {
  return (
    <div className="p-3">
      <h2>Records</h2>
    </div>
  );
}

function H2H() {
  const [year, setYear] = useState("2023");
  const yearOptions = ['all', '2023', '2022', '2021', '2020']
  const teams = smdTeams

  const handleYear  = (event) => {
    setYear(event.target.value);
    console.log(`year=${event.target.value}`)
  };

  const yearMenuItems = []
  for (let i = 0; i < yearOptions.length; i++) {
    yearMenuItems.push(<MenuItem key={yearOptions[i]} value={yearOptions[i]}>{yearOptions[i]}</MenuItem>);
  }

  return (
    <div>
      <Box >
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small" >
            <InputLabel id={'year'}>{'Year'}</InputLabel>
            <Select
            labelId={'year'}
            id={'year'}
            value={year}
            label={'Year'}
            onChange={handleYear}
            >
            {yearMenuItems}
            </Select>
        </FormControl>
        </Box>
      {<H2HStats year={year}/>}
    </div>
  );
}

function H2HStats(props) {

  const url = `https://supermegadynasty.com/smd/team_records/${props.year}/combined.json`
  console.log(url)

  const headCells = [
    {
      id: 'username',
      numeric: false,
      disablePadding: false,
      label: 'Team',
      borderRight: 1,
    },
    {
      id: 'total_wins',
      numeric: true,
      disablePadding: false,
      label: 'W',
    },
    {
      id: 'total_losses',
      numeric: true,
      disablePadding: false,
      label: 'L',
    },
    {
      id: 'win_pct',
      numeric: true,
      disablePadding: false,
      label: 'Win %',
    },
    {
      id: 'h2h_record',
      numeric: true,
      disablePadding: false,
      label: 'vs H2H',
    },
    {
      id: 'h2h_pct',
      numeric: true,
      disablePadding: false,
      label: 'H2H %',
    },
    {
      id: 'record_vs_median',
      numeric: true,
      disablePadding: false,
      label: 'vs Median',
    },
    {
      id: 'median_pct',
      numeric: true,
      disablePadding: false,
      label: 'Median %',
    },
    {
      id: 'postseason_wins',
      numeric: true,
      disablePadding: false,
      label: 'PostW',
    },
    {
      id: 'postseason_losses',
      numeric: true,
      disablePadding: false,
      label: 'PostL',
    },
    {
      id: 'record_vs_beef6191',
      numeric: true,
      disablePadding: false,
      label: 'vs Beef (Jordan)',
    },
    {
      id: 'record_vs_caseyott',
      numeric: true,
      disablePadding: false,
      label: 'vs Casey',
    },
    {
      id: 'record_vs_chasemanz',
      numeric: true,
      disablePadding: false,
      label: 'vs Chase',
    },
    {
      id: 'record_vs_claytondavis',
      numeric: true,
      disablePadding: false,
      label: 'vs Clayton',
    },
    {
      id: 'record_vs_devanott',
      numeric: true,
      disablePadding: false,
      label: 'vs Devan',
    },
    {
      id: 'record_vs_eschnite16',
      numeric: true,
      disablePadding: false,
      label: 'vs Evan',
    },
    {
      id: 'record_vs_farinamh28',
      numeric: true,
      disablePadding: false,
      label: 'vs Farina',
    },
    {
      id: 'record_vs_schnjake',
      numeric: true,
      disablePadding: false,
      label: 'vs Jake',
    },
    {
      id: 'record_vs_johnnytecmo',
      numeric: true,
      disablePadding: false,
      label: 'vs Johnny',
    },
    {
      id: 'record_vs_mkurtz8',
      numeric: true,
      disablePadding: false,
      label: 'vs Kurtz',
    },
    {
      id: 'record_vs_milwaukeemojo',
      numeric: true,
      disablePadding: false,
      label: 'vs Mojo (Tim)',
    },
    {
      id: 'record_vs_stacheman414',
      numeric: true,
      disablePadding: false,
      label: 'vs Stache (Pat)',
    },
  ];

  console.log('H2H PAGE')

  const title = `H2H Stats: ${props.year}`

  return (
    <div className="md:flex flex-wrap">
      <div className="w-200">
        <div>
        </div >
        {/* {product.data.player_map.map(item => <div>{item.full_name}</div>)} */}
        <MuiTable table='h2h' headCells={headCells} url={url} title={title} page={15}/>
      </div>
  </div>
  );
}

function Scoring() {
  return (
    <div className="p-3">
      <h2>Scoring</h2>
      <p>Scoring Average by Year(overall), scoring avg by position, bench scoring</p>
      <BarChart />
      <Sample />

    </div>
  );
}