import React from "react";

function Footer() {
  return (
    // <footer className="bg-gray-200 text-center text-xs p-3 absolute bottom-0">
    <footer className="bg-gray-200 text-black text-center text-xs p-3 fixed bottom-0 w-full">
      v.0.3.5 (12/6/22)
    </footer>
  );
}

export default Footer;
