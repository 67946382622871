import React, {useMemo, useState}from 'react'
// import { useParams } from 'react-router-dom'
// import Loader from '../Components/Loader'
import { useAxiosGet } from '../Hooks/HttpRequests'
import Table from '../Components/Table'
import TradeTable from '../Components/TradeTable'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
import MuiTable from '../Components/MuiTable'
import OutlinedCard from '../Components/OutlinedCard';
import { TableTooltip, BasicTooltip, Chip } from '@nivo/tooltip'
import { ResponsiveChord, Chord, ArcDatum, ArcTooltipComponentProps, RibbonTooltipComponentProps } from '@nivo/chord'


function ChordTest(){


  const ArcTooltip = ({ arc }: ArcTooltipComponentProps) => (
    <BasicTooltip
        id={`Custom arc tooltip, ${arc.label}`}
        value={arc.formattedValue}
        color={arc.color}
        enableChip={true}
        textcolor={arc.color}
    />
  )

  const RibbonTooltip = ({ ribbon }: RibbonTooltipComponentProps) => (
      <TableTooltip
          rows={[
              [
                  <Chip key="chip" color={ribbon.source.color} />,
                  'Source (custom)',
                  <strong key="id">{ribbon.source.id}</strong>,
                  ribbon.source.value,
              ],
              [
                  <Chip key="chip" color={ribbon.target.color} />,
                  'Target (custom)',
                  <strong key="id">{ribbon.target.id}</strong>,
                  ribbon.target.value,
              ],
          ]}
      />
  )

  const MyChord = ({ data /* see data tab */ }) => (
    <Chord
      data={[
        //casey
        [
          0,3,3,1,0,1,3,1,2,1,3,0
        ],
        //evan
        [
          3,0,1,6,1,0,8,2,1,2,3,1
        ],
        //chase
        [
          3,1,0,3,3,0,9,3,0,0,1,1
        ],
        //mojo
        [
          1,6,3,0,7,3,19,1,0,4,3,3
        ],
        //farina
        [
          0,1,3,7,0,0,10,4,0,0,0,3
        ],
        //devan
        [
          1,0,0,3,0,0,2,1,0,2,0,1
        ],
        //beef
        [
          3,8,9,19,10,2,0,5,3,11,2,1
        ],
        //johnny
        [
          1,2,3,1,4,1,5,0,0,1,0,0
        ],
        //kurtz
        [
          2,1,0,0,0,0,3,0,0,2,0,1
        ],
        //clayton
        [
          1,2,0,4,0,2,11,1,2,0,0,1
        ],
        //jake
        [
          3,3,1,3,0,0,2,0,0,0,0,1
        ],
        //stache
        [
          0,1,1,3,3,1,1,0,1,1,1,0
        ],
      ]}
    height={750}
    width={750
  }
    keys={[ 'Casey', 'Evan', 'Chase', 'Mojo', 'Farina', 'Devan', 'Beef', 'Johnny', 'Kurtz', 'Clayton', 'Jake', 'Stache' ]}
        margin={{ top: 60, right: 60, bottom: 90, left: 60 }}
        valueFormat=".2f"
        padAngle={0.02}
        innerRadiusRatio={0.96}
        innerRadiusOffset={0.02}
        inactiveArcOpacity={0.25}
        // arcTooltip={ArcTooltip}
        // ribbonTooltip={RibbonTooltip}
        arcBorderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.6
                ]
            ]
        }}
        activeRibbonOpacity={1}
        inactiveRibbonOpacity={0.25}
        ribbonBorderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.6
                ]
            ]
        }}
        labelRotation={-90}
        labelTextColor={{
          from: 'color',
          modifiers: [
              [
                  'darker',
                  1
              ]
          ]
      }}
        colors={{ scheme: 'category10' }}
        motionConfig="stiff"
        // legends={[
        //     {
        //         anchor: 'bottom',
        //         direction: 'row',
        //         justify: false,
        //         translateX: 0,
        //         translateY: 70,
        //         itemWidth: 80,
        //         itemHeight: 14,
        //         itemsSpacing: 0,
        //         itemTextColor: '#999',
        //         itemDirection: 'left-to-right',
        //         symbolSize: 12,
        //         symbolShape: 'circle',
        //         effects: [
        //             {
        //                 on: 'hover',
        //                 style: {
        //                     itemTextColor: '#000'
        //                 }
        //             }
        //         ]
        //     }
        // ]}
    />
)

return (
  <div class="chord">
    <MyChord/>
  </div>
   
)
}

export default ChordTest