import React, {useMemo}from 'react'
// import { useParams } from 'react-router-dom'
// import Loader from '../Components/Loader'
import { useAxiosGet } from '../Hooks/HttpRequests'
import MuiTable from '../Components/MuiTable'
import finalPropsSelectorFactory from 'react-redux/es/connect/selectorFactory';


function Standings(props){
    //const { id } = useParams()
    const url = `https://supermegadynasty.com/${props.league}_2023_standings.json`

    const headCells = [
      {
        id: 'rank',
        numeric: true,
        disablePadding: false,
        label: 'Rank',
      },
      {
        id: 'name',
        numeric: true,
        disablePadding: false,
        label: 'Name',
      },
      {
        id: 'wins',
        numeric: true,
        disablePadding: false,
        label: 'Wins',
      },
      {
        id: 'losses',
        numeric: true,
        disablePadding: false,
        label: 'Losses',
      },
      {
        id: 'pf_points',
        numeric: true,
        disablePadding: false,
        label: 'PF',
      },
      {
        id: 'pa_points',
        numeric: true,
        disablePadding: false,
        label: 'PA',
      },
      {
        id: 'pp_points',
        numeric: true,
        disablePadding: false,
        label: 'MAX PF',
      },
      {
        id: 'eff',
        numeric: true,
        disablePadding: false,
        label: 'Efficiency%',
      },
      {
        id: 'pts_diff',
        numeric: true,
        disablePadding: false,
        label: 'Point Diff',
      },
      {
        id: 'gb',
        numeric: true,
        disablePadding: false,
        label: 'GB',
      },
      {
        id: 'pts_behind',
        numeric: true,
        disablePadding: false,
        label: 'Pts Behind',
      },
    ];

    const title = `${props.league} Standings`

    return (
    <>
      {/* <div>
        <h1 className="font-bold text-2xl">{title} </h1>
      </div> */}

      <div className="md:flex flex-wrap">
        <MuiTable headCells={headCells} url={url} title={title} page={15}/>
      </div>
      <div>
        add weekly rankings chart
      </div>
      </>
    )
}

export default Standings