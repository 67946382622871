import React, { useEffect } from "react";
import "./tailwind.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Views/Home";
import About from "./Views/About";
import Draft from "./Views/Draft";
import Standings from "./Views/Standings";
import Trades from "./Views/Trades";
import ChordTest from "./Views/Chordtest";
// import { ThemeProvider, createGlobalStyle } from 'styled-components'
import Team from "./Views/Team";
import League from "./Views/League";
import TrendingPlayers from "./Views/TrendingPlayers";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Amplify, Auth, Analytics } from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { useDispatch, useSelector, useStore } from "react-redux";

Amplify.configure(awsconfig);

//Record an event
// Analytics.record('some-event-name');

//Record a custom event
// Analytics.record({
//     name: 'Album',
//     attributes: { genre: 'Rock', year: '1989' }
// });


// Returns an array of groups

export default withAuthenticator(App);

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

// const GlobalStyle = createGlobalStyle`
// body {
//     background-color: ${props => props.theme.mode === 'dark' ? 'rgba(36,36,36)' : '#EEE'};
//     color: ${props => props.theme.mode === 'dark' ? '#EEE' : '#111'};
// }
// dark-menu {
//     background-color: ${props => props.theme.mode === 'dark' ? 'rgb(34, 72, 98)' : '#EEE'};
//     color: ${props => props.theme.mode === 'dark' ? '#EEE' : '#111'};
// }`

function App({signOut, user}) {

    async function getLeaguePromise(lg){
        const currentUserInfo = await Auth.currentUserInfo().then(function(result) {return result.attributes[`custom:${lg}`]})
        console.log(`User in ${lg}? ${currentUserInfo}`)
        // const league       =  currentUserInfo.attributes[`custom:${lg}`]
        return currentUserInfo // 'yes'
    }


    async function getAtts() {
        const currentUserInfo = await Auth.currentUserInfo()
        const atts = []
        const sleeper   = currentUserInfo.attributes['custom:sleeper-username']
        const smd       =  currentUserInfo.attributes['custom:smd']
        const miltown   = currentUserInfo.attributes['custom:miltown']
        const muff      =  currentUserInfo.attributes['custom:muff']
        atts.push(smd)
        atts.push(miltown)
        atts.push(muff)

        return atts
        }

    Analytics.record({
        name: 'login',
        immediate: true,
        attributes: {user: user.attributes.email}
        });


    console.log(user)
    const league = useSelector((state) => state.league.value)


    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <>
                {/* <GlobalStyle /> */}
                {/* <button onClick={signOut}>Sign out</button> */}
                    <Router>
                        <Header user={user.attributes.email} signOut={signOut}/>
                        <div className="p-3">
                            <Routes>
                            <Route exact path="/" element={<Home user={user} />} />
                            <Route path="/team" element={<Team />} />
                            <Route path="/league" element={<League />}/>
                            <Route path="/about" element={About} />
                            <Route path="/draft" element={<Draft />} />
                            <Route path="/standings" element={ <Standings user={user.attributes.email} league={league}/>}  />
                            <Route path="/trades" element={ <Trades /> } />
                            <Route path="/chord" element={ <ChordTest /> } />
                            <Route path="/trendingPlayers" element= {TrendingPlayers} />
                            </Routes>
                        </div>
                        <Footer />
                    </Router>
            </>
        </ThemeProvider>
    );
}

// export default App;
