import React from "react";
import Grid from "../Components/Grid";
import { useAxiosGet } from '../Hooks/HttpRequests';
import Box from '@mui/material/Box';
import { Divider, Paper } from "@mui/material";


function Draft() {

    const url =      `https://supermegadynasty.com/smd/draft/2024/draft.json`
    const pick1_url = `https://supermegadynasty.com/smd/draft/2024/draft_picks.json`
    const pick2_url = `https://supermegadynasty.com/smd/draft/2025/draft_picks.json`
    const pick3_url = `https://supermegadynasty.com/smd/draft/2026/draft_picks.json`

    let order = useAxiosGet(url)
    let picks1 = useAxiosGet(pick1_url)
    let picks2 = useAxiosGet(pick2_url)
    let picks3 = useAxiosGet(pick3_url)

    if(order.error){
        return (
            <div>
                <div className="bg-blue-300 mb-2 p-3">
                    If you see this error, there was an issue loading the data - It will never be fixed, you might as well give up.
                </div>
                <div className="bg-red-300 p-3">
                    There was an error please refresh or try again later, or don't IDGAF.
                </div>
            </div>
            )
    }

    if(order.data && picks1.data && picks2.data ){
        console.log('DRAFT PAGE')
        // console.log(order.data)
        // console.log(picks1.data)

        return (
            <div className="md:flex">
                <Box >
                    <Paper elevation={6} sx={{                    backgroundImage: 'none',
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',}}>
                    <div className="p-3 basis-1/2 md:basis-full  ">
                        <h1 className="font-bold text-2xl p-3">2024</h1>
                        <Grid picks={picks1.data} teams={order.data} />
                    </div>
                    <div>
                        <h1 className="font-bold text-2xl p-3">2025</h1>
                        <Grid picks={picks2.data} teams={order.data} />
                    </div>
                    <div>
                        <h1 className="font-bold text-2xl p-3">2026</h1>
                        <Grid picks={picks3.data} teams={order.data} />
                    </div>
                    <div>
                        <h1 className="font-bold text-2xl p-3"></h1>
                    </div>
                    </Paper>
                </Box>
            </div>
        )
    }
}

export default Draft;
