import React from "react";
import { LineChart, BarChart, TreeMap, ApexHeatMap, Sample } from "../Components/ReactApexChart";
import { useAxiosGetTree } from "../Hooks/HttpRequests";

function About() {

  const url = `https://supermegadynasty.com/smd/reports/starting_pct/2023/caseyott.json`
  // let response = useAxiosGetTree(url);
  // console.log(response)

  return (
    <div className="mb-3 md:flex">
      <h1 className="font-bold text-2xl mb-3">This is the about page</h1>
      <LineChart />
      <BarChart />
      <TreeMap url={url} />
      <ApexHeatMap />
      <Sample />
    </div>
  );
}

export default About();
