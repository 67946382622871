import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useAxiosGet } from '../Hooks/HttpRequests';
import { smdTeams } from '../data/teamMap';



const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

const card = (
    <React.Fragment>
    <CardContent>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        Trade
      </Typography>
      <Typography variant="h5" component="div">
        be{bull}nev{bull}o{bull}lent
      </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
        adjective
      </Typography>
      <Typography variant="body2">
        well meaning and kindly.
        <br />
        {'"a benevolent smile"'}
      </Typography>
    </CardContent>
    <CardActions>
      <Button size="small">Learn More</Button>
    </CardActions>
  </React.Fragment>
);

function Xcard (props){
    <React.Fragment>
    <CardContent>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        Trade
      </Typography>
      <Typography variant="h5" component="div">
        {props.data.username}
      </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
        adjective
      </Typography>
      <Typography variant="body2">
        well meaning and kindly.
        <br />
        {'"a benevolent smile"'}
      </Typography>
    </CardContent>
    <CardActions>
      <Button size="small">Learn More</Button>
    </CardActions>
  </React.Fragment>
}

export default function OutlinedCard(props) {

    const url = props.url

    let product = useAxiosGet(url)
    let rows = product.data
    let trades = []

    if(product.error){
        return (
        <div>
            <div className="bg-blue-300 mb-2 p-3">
                If you see this error, there was an issue loading the data - It will never be fixed, you might as well give up.
            </div>
            <div className="bg-red-300 p-3">
                There was an error please refresh or try again later, or don't IDGAF.
            </div>
        </div>
        )
    }
    if(product.data) {

        console.log("Table type...trades")
        let map = smdTeams

        // iterate within the compiledData objects
        rows.forEach(rowObj => {
            // filter function return the objects that fulfil the requirements (if return is true, then the object is added, else not)
            const objOwnerId = map.filter(mapObj => {
            // return the objects with the same location only
            return mapObj.roster_id === rowObj.roster_ids[0]
            });

            const objPrevOwnerId = map.filter(mapObj => {
            // return the objects with the same location only
            return mapObj.roster_id === rowObj.roster_ids[1]
            });

            // const objRosterId = map.filter(mapObj => {
            //   // return the objects with the same location only
            //   return mapObj.roster_id === rowObj.roster_ids[1]
            // });

            // iterate within the objects returned
            objOwnerId.forEach(obj => {
                // within the rowObject, get the year parameter and fill the price that matched the same year
                rowObj.roster1 = obj.username
            });

            // iterate within the objects returned
            objPrevOwnerId.forEach(obj => {
                // within the rowObject, get the year parameter and fill the price that matched the same year
                rowObj.roster2 = obj.username
            });
            console.log(rowObj)

            // let player1_arr = []
            // rowObj.forEach(player => {
            //     console.log(player)
            //     player1_arr.push(player)
            // })

            let trade = {
                'username': rowObj.roster1,
                'roster_id': rowObj.roster_ids[0],
                'adds': rowObj.adds,
                'drops': rowObj.drops,
                'add_picks': rowObj.draft_picks,
                'username2': rowObj.roster2,
                'roster_id2': rowObj.roster_ids[1],
                'transaction_id': rowObj.transaction_id,

                // 'adds': rowObj.roster1_player_adds,
            };

            // roster1['adds'] = player1_arr
            // roster1['username'] = rowObj.roster1

            trades.push(trade)
        });
        console.log(trades)
    }

    if (trades){
      return (
        trades.forEach(trade => {
          <div>{trade}</div>
          // <Box sx={{ minWidth: 275 }}>
          //     <Card sx={{ minWidth: 275 }}>
          //         <CardContent>
          //             <Typography sx={{ fontSize: 14 }} gutterBottom>
          //             date
          //             </Typography>
          //             <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
          //             trade.transaction_id
          //             </Typography>
          //             <Typography variant="h5" component="div">
          //             username
          //             </Typography>
          //             <Typography sx={{ mb: 1.5 }} color="text.secondary">
          //             adds
          //             </Typography>
          //             <Typography variant="body2">
          //             asset 1
          //             <br />
          //             asset 2
          //             </Typography>
          //             <Typography variant="h5" component="div">
          //             username2
          //             </Typography>
          //             <Typography sx={{ mb: 1.5 }} color="text.secondary">
          //             adds
          //             </Typography>
          //             <Typography variant="body2">
          //             asset 3
          //             <br />
          //             asset 4
          //             </Typography>
          //         </CardContent>
          //         {/* <CardActions>
          //             <Button size="small">Learn More</Button>
          //         </CardActions> */}
          //     </Card>
          // </Box>
        })
      )
    }
}

