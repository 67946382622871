export const smdTeams = [
    {
        "username": "beef6191",
        "owner_id": "572241150179631104",
        "roster_id": 7,
        "team_name": "NFL Owner VR"
    },
    {
        "username": "caseyott",
        "owner_id": "399618273299177472",
        "roster_id": 1,
        "team_name": "Dutch Apple Ass"
    },
    {
        "username": "chasemanz",
        "owner_id": "572114850819596288",
        "roster_id": 3,
        "team_name": "chasemanz"
    },
    {
        "username": "claytondavis",
        "owner_id": "459760307363901440",
        "roster_id": 10,
        "team_name": "claytondavis"
    },
    {
        "username": "devanott",
        "owner_id": "459403507259142144",
        "roster_id": 6,
        "team_name": "The 332 BOY$"
    },
    {
        "username": "eschnite16",
        "owner_id": "573595871040663552",
        "roster_id": 2,
        "team_name": "The Big Cheese"
    },
    {
        "username": "farinamh28",
        "owner_id": "339955305209196544",
        "roster_id": 5,
        "team_name": "Ekeler's Pecker"
    },
    {
        "username": "johnnytecmo",
        "owner_id": "573361931242192896",
        "roster_id": 8,
        "team_name": "johnnytecmo"
    },
    {
        "username": "milwaukeemojo",
        "owner_id": "376141359662538752",
        "roster_id": 4,
        "team_name": "The Three-Eyed Emperor"
    },
    {
        "username": "mkurtz8",
        "owner_id": "459402968756645888",
        "roster_id": 9,
        "team_name": "WR >= QB?"
    },
    {
        "username": "schnjake",
        "owner_id": "573570723954946048",
        "roster_id": 11,
        "team_name": "Mills Mafia"
    },
    {
        "username": "stacheman414",
        "owner_id": "460564788167045120",
        "roster_id": 12,
        "team_name": "StacheMan414"
    }
]

export const miltownTeams = [
    {
        "username": "cwilling",
        "owner_id": "336193163255353344",
        "roster_id": 9,
        "team_name": "Game Film Grinders"
    },
    {
        "username": "farinamh28",
        "owner_id": "339955305209196544",
        "roster_id": 1,
        "team_name": "Team Georgie Boo Boos"
    },
    {
        "username": "milwaukeemojo",
        "owner_id": "376141359662538752",
        "roster_id": 2,
        "team_name": "Milwaukee Mojo"
    },
    {
        "username": "caseyott",
        "owner_id": "399618273299177472",
        "roster_id": 4,
        "team_name": "Young Spuds"
    },
    {
        "username": "albrechtsm05",
        "owner_id": "399683442977079296",
        "roster_id": 5,
        "team_name": "albrechtsm05"
    },
    {
        "username": "anorris",
        "owner_id": "399692595418116096",
        "roster_id": 10,
        "team_name": "anorris"
    },
    {
        "username": "seyees",
        "owner_id": "399757629427056640",
        "roster_id": 11,
        "team_name": "seyees"
    },
    {
        "username": "hornypooches",
        "owner_id": "399975241561939968",
        "roster_id": 3,
        "team_name": "HornyPooches"
    },
    {
        "username": "mahlke",
        "owner_id": "402317756503982080",
        "roster_id": 6,
        "team_name": "Mahlke"
    },
    {
        "username": "svr",
        "owner_id": "403226810340356096",
        "roster_id": 12,
        "team_name": "Third Tier Turds"
    },
    {
        "username": "bigbowarbrand",
        "owner_id": "406944063036645376",
        "roster_id": 7,
        "team_name": "BigBowarBrand"
    },
    {
        "username": "devanott",
        "owner_id": "459403507259142144",
        "roster_id": 8,
        "team_name": "DevanOtt"
    }
]