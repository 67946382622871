import * as React from 'react';

import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { useAxiosGet } from '../Hooks/HttpRequests'
import { makeStyles } from '@material-ui/core/styles';
import { smdTeams } from '../data/teamMap';

const useStyles = makeStyles({
  customTable: {
    "& .MuiTableCell-sizeSmall": {
      padding: "6px 0px 6px 16px", // <-- arbitrary value
      width: "max-content"
    }
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
// function stableSort(array, comparator) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }
function stableSort(array, comparator) {
  if (Array.isArray(array)) {
    return array.sort((a, b) => {
      const order = comparator(a, b);
      if (order !== 0) {
        return order;
      }
      return 0;
    });
  } else {
    // Handle the case where `array` is not an array
    console.error('stableSort: Input is not an array');
    console.log(array);
    return array;
  }
}


function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox"> */}
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          /> */}
        {/* </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'left'}
            // padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            width= "max-content"
            // padding= "none"
            sx={{ fontSize: "small", padding: "6px 0px 6px 6px" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              // hideSortIcon='false'
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array.isRequired
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        // ...(numSelected > 0 && {
        //   bgcolor: (theme) =>
        //     alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        // }),
      }}
    >
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        id="tableTitle"
        component="div"
        className="uppercase"
      >
        {props.title}
      </Typography>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function MuiTable(props) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('rank');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.page);

  const url = props.url

  const classes = useStyles();

  let product = useAxiosGet(url)

  if(product.error){
    return (
      <div>
        <div className="bg-blue-300 mb-2 p-3">
              If you see this error, there was an issue loading the data - It will never be fixed, you might as well give up.
          </div>
          <div className="bg-red-300 p-3">
              There was an error please refresh or try again later, or don't IDGAF.
          </div>
      </div>
    )
  }

  let rows = product.data
  console.log(rows)

  if(product.data) {
    if (props.table === 'rosters') {
      rows = product.data.player_map
    }

    if (props.table === 'most_traded') {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };

      // console.log(event.toLocaleDateString('de-DE', options));

      rows.forEach(rowObj => {
        Object.keys(rowObj).forEach(function(key) {
          if (key === 'last_trade'){
            // console.log(key)
            // console.log(rowObj[key])
            // var d = new Date(rowObj[key]).toLocaleDateString('en-US', options)
            // var yr = d.getFullYear()
            // var mo = d.getMonth()
            // var dt = d.getDate()
            rowObj[key] = new Date(rowObj[key]).toLocaleDateString('en-US', options)
            // rowObj[key] = `${yr}/${mo}/${dt}`
          }
        })
      //   var d = new Date(0)
      //   rowObj.last_trade = d.setUTCSeconds(rowObj.last_trade)
      });
    }

    if (props.table === 'traded_picks') {
      let map = smdTeams
      // iterate within the compiledData objects
      rows.forEach(rowObj => {
        // filter function return the objects that fulfil the requirements (if return is true, then the object is added, else not)
        const objOwnerId = map.filter(mapObj => {
            // return the objects with the same location only
            return mapObj.roster_id === rowObj.owner_id
        });

        const objPrevOwnerId = map.filter(mapObj => {
          // return the objects with the same location only
          return mapObj.roster_id === rowObj.previous_owner_id
        });

        const objRosterId = map.filter(mapObj => {
          // return the objects with the same location only
          return mapObj.roster_id === rowObj.roster_id
        });

        // iterate within the objects returned
        objOwnerId.forEach(obj => {
            // within the rowObject, get the year parameter and fill the price that matched the same year
            rowObj.owner_id = obj.username
        });

        // iterate within the objects returned
        objPrevOwnerId.forEach(obj => {
          // within the rowObject, get the year parameter and fill the price that matched the same year
          rowObj.previous_owner_id = obj.username
        });

        // iterate within the objects returned
        objRosterId.forEach(obj => {
          // within the rowObject, get the year parameter and fill the price that matched the same year
          rowObj.roster_id = obj.username
        });
      });
    }

    if (props.table === 'trades') {
      console.log("Table type...trades")
      let map = smdTeams
      // iterate within the compiledData objects
      rows.forEach(rowObj => {
        // filter function return the objects that fulfil the requirements (if return is true, then the object is added, else not)
        const objOwnerId = map.filter(mapObj => {
            // return the objects with the same location only
            return mapObj.roster_id === rowObj.roster_ids[0]
        });

        const objPrevOwnerId = map.filter(mapObj => {
          // return the objects with the same location only
          return mapObj.roster_id === rowObj.roster_ids[1]
        });

        // const objRosterId = map.filter(mapObj => {
        //   // return the objects with the same location only
        //   return mapObj.roster_id === rowObj.roster_ids[1]
        // });

        // iterate within the objects returned
        objOwnerId.forEach(obj => {
            // within the rowObject, get the year parameter and fill the price that matched the same year
            rowObj.roster1 = obj.username
        });

        // iterate within the objects returned
        objPrevOwnerId.forEach(obj => {
          // within the rowObject, get the year parameter and fill the price that matched the same year
          rowObj.roster2 = obj.username
        });


        console.log(rowObj)
      });
    }
  }


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelected = rows.map((n) => n.name);
  //     setSelected(newSelected);
  //     return;
  //   }
  //   setSelected([]);
  // };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  
  
  if(rows){
    // console.log(rows)


    return (
      <Box >
        <Paper
          sx={{
            mb: 2,
            backgroundImage: 'none',
            backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            }}
          elevation={6}
        >
          <EnhancedTableToolbar className="uppercase" numSelected={selected.length} title={props.title}/>
          <TableContainer
            width="max-content"
          >
            <Table
              // classes={{root: classes.customTable}}
              // sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                // onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                headCells={props.headCells}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                  rows.sort(getComparator(order, orderBy)).slice() */}
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    // let testcols = []
                    // for (let i = 0; i < props.cols.length; i++) {
                    //   testcols.push(props.cols[i]);
                    // }
                    // console.log(testcols)
                    // let cols = [row.username, row.trades, row.trades_2022, row.trades_2021, row.trades_2020]

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        selected={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox"> */}
                          {/* <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          /> */}
                        {/* </TableCell> */}
                        {/* <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.username}
                        </TableCell> */}

                        {props.headCells.map(header => (
                          <TableCell  sx={{ fontSize: "small", padding: "6px 0px 6px 6px" }} className="text-sm" size="small" align="left">{row[header.id]}</TableCell>
                        ))}
                        {/* {cols.map((cell, i) => {
                          return (
                            <TableCell align="left">{cell}</TableCell>
                          );
                        })
                        } */}

                        {/* <TableCell align="left">{row.trades}</TableCell>
                        <TableCell align="left">{row.trades_2022}</TableCell>
                        <TableCell align="left">{row.trades_2021}</TableCell>
                        <TableCell align="left">{row.trades_2020}</TableCell> */}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 25, 30, 50]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        {/* <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        /> */}
      </Box>
    );
  }
}