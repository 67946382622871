import React from "react";
import { Link } from "react-router-dom";
// import {
//     faTableCells,
//     faCircleInfo,
//     faRankingStar,
//     faRightLeft,
//     faHouse,
//     faBackward
// } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function NavLink(props) {
    return (
        <li>
            <Link
                to={props.page}
                className="text-gray-300 py-3 border-t border-b block"
                onClick={props.closeMenu}
            >
                <span className="text-xl p-3">
                <FontAwesomeIcon icon={props.icon} />
                </span>
                {props.name}
            </Link>
        </li>
    );
}
export function MiniNavLink(props) {
    return (
        <li>
            <Link
                to={props.page}
                className="text-gray-500 py-3 block"
                onClick={props.closeMenu}
            >
                <span className="p-3">
                <FontAwesomeIcon icon={props.icon} />
                </span>
                {props.name}
            </Link>
        </li>
    );
}
export default NavLink;
