import { createSlice } from '@reduxjs/toolkit'

export const leagueSlice = createSlice({
  name: 'league',
  initialState: {
    value: 'smd',
  },
  reducers: {
    update: (state, action) => {
      console.log(`League was: ${state.league}`)
      state.league = action.payload
      console.log(`League now: ${state.league}`)
    },
    // update: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1
    // },
  },
});

// export const selectLeague = (state) => state.league.value


// Action creators are generated for each case reducer function
export const { update } = leagueSlice.actions

export default leagueSlice.reducer
