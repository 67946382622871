import React, {useMemo, useState}from 'react'
// import { useParams } from 'react-router-dom'
// import Loader from '../Components/Loader'
import { useAxiosGet } from '../Hooks/HttpRequests'
import Table from '../Components/Table'
import TradeTable from '../Components/TradeTable'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
import MuiTable from '../Components/MuiTable'
import OutlinedCard from '../Components/OutlinedCard';
import { ResponsiveChord } from '@nivo/chord';
import ChordTest from './Chordtest';


function Trades(){
  //const { id } = useParams()
  // https://api.sleeper.app/v1/league/785959210730311680/traded_picks
  const league_id = '785959210730311680'
  const baseUrl = 'https://api.sleeper.app/v1/league/'
  const url = `${baseUrl}${league_id}/traded_picks`


  const [value, setValue] = useState("team");

  const handleClick = (event, newValue) => {
    setValue(newValue);
  };

  let product = useAxiosGet(url)

  if(product.error){
    return (
      <div>
        <div className="bg-blue-300 mb-2 p-3">
              If you see this error, there was an issue loading the data - It will never be fixed, you might as well give up.
          </div>
          <div className="bg-red-300 p-3">
              There was an error please refresh or try again later, or don't IDGAF.
          </div>
      </div>
    )
  }

  // if(product.loading){
  //     content = <Loader></Loader>
  // }

  if(product.data){
      return (
        <div className="h-2/4">
          {/* <h1 className="font-bold text-2xl">This is the Team page</h1> */}
          <Tabs
            value={value}
            onChange={handleClick}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            textColor="primary"
          >
            <Tab value="all" label="All Trades" />
            <Tab value="team" label="By Team" />
            <Tab value="partner" label="By Partner" />
            <Tab value="picks" label="Draft Pick Trades" />
            {/* <Tab value="last" label="Last Trades" /> */}
            <Tab value="most" label="Most Traded Players" />


          </Tabs>
          <Divider />
            <div className='h-2/4'>
              {value === 'all' && <AllTrades /> }
              {value === 'team' && <ByTeam />}
              {value === 'partner' && <ByPartner />}
              {value === 'picks' && <PickTrades />}
              {value === 'last' && <Last />}
              {value === 'most' && <Most />}

            </div>
        </div>
      );
  }
}

export default Trades

function AllTrades(props) {
  const url = "https://supermegadynasty.com/smd_sample_trades.json"
  //const url = "https://supermegadynasty.com/smd_trades.json"

  const headCells = [
    {
      id: 'status_updated',
      numeric: false,
      disablePadding: false,
      label: 'Date',
      maxWidth: 50,
      minWidth: 50,
      width: 50,
    },
    {
      id: 'roster1', //roster 1 and adds
      numeric: true,
      disablePadding: false,
      label: 'Team A',
      maxWidth: 50,
      minWidth: 50,
      width: 50,
    },
    {
      id: 'roster1_player_adds',
      numeric: true,
      disablePadding: false,
      label: 'Players A',
      maxWidth: 50,
      minWidth: 50,
      width: 50,
    },
    // {
    //   id: 'roster1_pick_adds',
    //   numeric: true,
    //   disablePadding: false,
    //   label: 'Picks A',
    //   maxWidth: 50,
    //   minWidth: 50,
    //   width: 50,
    // // },
    {
      id: 'roster2', //roster 2 and drops
      numeric: true,
      disablePadding: false,
      label: 'Team B',
      maxWidth: 50,
      minWidth: 50,
      width: 50,
    },
    {
      id: 'hold_drops',
      numeric: true,
      disablePadding: false,
      label: 'Assets B',
      maxWidth: 50,
      minWidth: 50,
      width: 50,
    },
    // {
    //   id: 'draft_picks',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Draft Picks',
    //   maxWidth: 50,
    //   minWidth: 50,
    //   width: 50,
    // },
    // {
    //   id: 'roster2_pick_adds',
    //   numeric: true,
    //   disablePadding: false,
    //   label: 'Picks B',
    //   maxWidth: 50,
    //   minWidth: 50,
    //   width: 50,
    // },
  ];

  // let product = useAxiosGet(url)

  // if(product.error){
  //   return (
  //     <div>
  //       <div className="bg-blue-300 mb-2 p-3">
  //             If you see this error, there was an issue loading the data - It will never be fixed, you might as well give up.
  //         </div>
  //         <div className="bg-red-300 p-3">
  //             There was an error please refresh or try again later, or don't IDGAF.
  //         </div>
  //     </div>
  //   )
  // }
  // if(product.data){
  //   console.log('ALL TRADES PAGE')

  return (
    <div className="md:flex flex-wrap max-w-prose">
      work in progress
      {/* <TradeTable columns={columns} data={product.data} /> */}
      {/* <OutlinedCard table="trades" headCells={headCells} url={url} title="All Trades" page={25}/> */}
    </div>
  );
  // }
}

function ByTeam(props) {
  const league_id = '785959210730311680'
  const baseUrl = 'https://supermegadynasty.com/smd/output/all'
  const url = `${baseUrl}/smd_complete_trades.json`

  const headCells = [
    {
      id: 'username',
      numeric: false,
      disablePadding: false,
      label: 'Team',
      maxWidth: 50,
      minWidth: 50,
      width: 50,
    },
    {
      id: 'trades',
      numeric: true,
      disablePadding: false,
      label: 'All Trades',
      maxWidth: 50,
      minWidth: 50,
      width: 50,
    },
    {
      id: 'trades_2023',
      numeric: true,
      disablePadding: false,
      label: '2023',
      maxWidth: 50,
      minWidth: 50,
      width: 50,
    },
    {
      id: 'trades_2022',
      numeric: true,
      disablePadding: false,
      label: '2022',
      maxWidth: 50,
      minWidth: 50,
      width: 50,
    },
    {
      id: 'trades_2021',
      numeric: true,
      disablePadding: false,
      label: '2021',
      maxWidth: 50,
      minWidth: 50,
      width: 50,
    },
    {
      id: 'trades_2020',
      numeric: true,
      disablePadding: false,
      label: '2020',
      maxWidth: 50,
      minWidth: 50,
      width: 50,
    }
  ];

  let product = useAxiosGet(url)

  if(product.error){
    return (
      <div>
        <div className="bg-blue-300 mb-2 p-3">
              If you see this error, there was an issue loading the data - It will never be fixed, you might as well give up.
          </div>
          <div className="bg-red-300 p-3">
              There was an error please refresh or try again later, or don't IDGAF.
          </div>
      </div>
    )
  }
  if(product.data){
    //console.log(product.data)

    return (
      <div className="md:flex flex-wrap max-w-prose">
        {/* <TradeTable columns={columns} data={product.data} /> */}
        <MuiTable headCells={headCells} url={url} title="Total Trades by Team" page={25}/>
      </div>
    );
  }
}

function ByPartner(props) {
  // const league_id = '785959210730311680'
  const baseUrl = 'https://supermegadynasty.com'
  const url = `${baseUrl}/smd_trades_by_partner.json`

  const headCells = [
    {
      id: 'username',
      numeric: false,
      disablePadding: false,
      label: 'Team',
      maxWidth: 50,
      minWidth: 50,
      width: 50,
    },
    {
      id: 'total',
      numeric: true,
      disablePadding: false,
      label: 'All Trades',
      maxWidth: 50,
      minWidth: 50,
      width: 50,
    },
    {
      id: 'caseyott',
      numeric: true,
      disablePadding: false,
      label: 'caseyott',
      maxWidth: 50,
      minWidth: 50,
      width: 50,
    },
    {
      id: 'eschnite16',
      numeric: true,
      disablePadding: false,
      label: 'eschnite16',
      maxWidth: 50,
      minWidth: 50,
      width: 50,
    },
    {
      id: 'chasemanz',
      numeric: true,
      disablePadding: false,
      label: 'chasemanz',
      maxWidth: 50,
      minWidth: 50,
      width: 50,
    },
    {
      id: 'milwaukeemojo',
      numeric: true,
      disablePadding: false,
      label: 'milwaukeemojo',
      maxWidth: 50,
      minWidth: 50,
      width: 50,
    },
    {
      id: 'farinamh28',
      numeric: true,
      disablePadding: false,
      label: 'farinamh28',
      maxWidth: 50,
      minWidth: 50,
      width: 50,
    },
    {
      id: 'devanott',
      numeric: true,
      disablePadding: false,
      label: 'devanott',
      maxWidth: 50,
      minWidth: 50,
      width: 50,
    },
    {
      id: 'beef6191',
      numeric: true,
      disablePadding: false,
      label: 'beef6191',
      maxWidth: 50,
      minWidth: 50,
      width: 50,
    },
    {
      id: 'johnnytecmo',
      numeric: true,
      disablePadding: false,
      label: 'johnnytecmo',
      maxWidth: 50,
      minWidth: 50,
      width: 50,
    },
    {
      id: 'mkurtz8',
      numeric: true,
      disablePadding: false,
      label: 'mkurtz8',
      maxWidth: 50,
      minWidth: 50,
      width: 50,
    },
    {
      id: 'claytondavis',
      numeric: true,
      disablePadding: false,
      label: 'claytondavis',
      maxWidth: 50,
      minWidth: 50,
      width: 50,
    },
    {
      id: 'schnjake',
      numeric: true,
      disablePadding: false,
      label: 'schnjake',
      maxWidth: 50,
      minWidth: 50,
      width: 50,
    },
    {
      id: 'stacheman414',
      numeric: true,
      disablePadding: false,
      label: 'stacheman414',
      maxWidth: 50,
      minWidth: 50,
      width: 50,
    }
  ];

  let product = useAxiosGet(url)

  if(product.error){
    return (
      <div>
        <div className="bg-blue-300 mb-2 p-3">
              If you see this error, there was an issue loading the data - It will never be fixed, you might as well give up.
          </div>
          <div className="bg-red-300 p-3">
              There was an error please refresh or try again later, or don't IDGAF.
          </div>
      </div>
    )
  }
  if(product.data){
    console.log(product.data)

    return (
      <div className="md:flex flex-wrap max-w-prose h-2/4">
        {/* <TradeTable columns={columns} data={product.data} /> */}
        <MuiTable headCells={headCells} url={url} title="Total Trades by Partner" page={25}/>
        <ChordTest/>
        <br></br>
      </div>
    );
  }
}

function Chord() {
  return (
    <ChordTest/>
  );
}

function PickTrades() {

  const league_id = '785959210730311680'
  const baseUrl = 'https://api.sleeper.app/v1/league/'
  const url = `${baseUrl}${league_id}/traded_picks`

  const headCells = [
    {
      id: 'season',
      numeric: false,
      disablePadding: true,
      label: 'Szn',
    },
    {
      id: 'round',
      numeric: true,
      disablePadding: false,
      label: 'Rd',
    },
    {
      id: 'roster_id',
      numeric: true,
      disablePadding: false,
      label: 'Pick (Team)',
    },
    {
      id: 'previous_owner_id',
      numeric: true,
      disablePadding: false,
      label: 'From',
    },
    {
      id: 'owner_id',
      numeric: true,
      disablePadding: false,
      label: 'To',
    }
  ];

  return (
    <div className="md:flex flex-wrap max-w-prose">
      <MuiTable table='traded_picks' headCells={headCells} url={url} title="Traded Draft Picks" page={50}/>
    </div>
  );

}

function Last() {
  return (
    <div className="p-3">
      <h2>Last Trade by Each Team</h2>
    </div>
  );
}

function Most() {

  const league_id = '785959210730311680'
  const baseUrl = 'https://supermegadynasty.com'
  const url = `${baseUrl}/smd_all_most_traded_players.json`

  const headCells = [
    {
      id: 'trades',
      numeric: false,
      disablePadding: false,
      label: 'Trades',
    },
    {
      id: 'full_name',
      numeric: true,
      disablePadding: false,
      label: 'Player',
    },
    {
      id: 'last_trade',
      numeric: true,
      disablePadding: false,
      label: 'Last Trade',
    }
  ];

  console.log('MOST TRADED PLAYERS PAGE')

  return (
    <div className="md:flex flex-wrap max-w-prose">
      <MuiTable table="most_traded" headCells={headCells} url={url} title="Most Traded Players" page={50}/>

    </div>
  );
}