import React from "react";
import Navigation from "./Navigation";
import Drawer from "./Drawer";


function Header(props) {
  return (
    <header className="border-b	 p-3 flex justify-between items-center">
      <Navigation user={props.user} signOut={props.signOut}/>
    </header>
  );
}

export default Header;
