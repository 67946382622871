import React, { useState } from "react";
import { useReducedMotion } from "react-spring";
import Standings from "./Standings";
// import EnhancedTable from "../Components/MuiTradeTable";
// import { Counter } from "../features/counter/Counter";
import { useDispatch, useSelector, useStore } from "react-redux";
import { selectLeague, update } from '../features/league/leagueSlice'
import { League } from "../features/league/League";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ComplexGrid from "../Components/ComplexGrid";
import Grid from '@mui/material/Grid';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import {
  faTableCells,
  faCircleInfo,
  faRankingStar,
  faRightLeft,
  faHouse,
  faBackward,
  faFootball,
  faPeopleGroup,
  faSquareArrowUpRight,
  faSignOut
} from "@fortawesome/free-solid-svg-icons";
import { Divider } from "@mui/material";


const LeagueSelect = (props) => {
  const { bar } = useSelector(state => state.league.value);
  let LeagueOptions = []

  if (props.user == 'ottcs29@gmail.com'){
    LeagueOptions = ['smd', 'miltown', 'muffbowl', 'fake', 'super-fake']
  }
  else {
    LeagueOptions = ['smd']
  }

  let league = useSelector((state) => state.league.value)
  const dispatch = useDispatch()
  const menuItems = [];

  const [lg, setLg] = React.useState(league);

  const handleChange = (event) => {
    // console.log(`new lg= ${event.target.value}`)
    setLg(event.target.value);
    league = dispatch(update(event.target.value));
  }

    for (let i = 0; i < LeagueOptions.length; i++) {
        // note: we are adding a key prop here to allow react to uniquely identify each
        // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
        menuItems.push(<MenuItem key={LeagueOptions[i]} value={LeagueOptions[i].toLowerCase()}>{LeagueOptions[i]}</MenuItem>);
    }

  return (
    <>
          <Box >
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small" >
                <InputLabel id={props.name}>{props.name}</InputLabel>
                <Select
                labelId={props.name}
                id={props.name}
                value={lg}
                label={props.name}
                onChange={handleChange}
                // onChange={() =>
                //   dispatch(update(menuItems[1].key))
                // }
                // onChange={() => dispatch(update())}
                >
                {menuItems}
                </Select>
            </FormControl>
        </Box>
          {/* <Standings league={lg} /> */}

    </>
  )
}


const lg = {
    title: 'League',
    subtitle: 'Compare teams',
    // desc: "Breakdown of Scoring by Lineup Positions",
    page: "/league",
    icon: faFootball
};

const team = {
  title: 'Team',
  subtitle: 'Team Statistics',
  // desc: "Team Scoring, H2H info, Historical Roster Comparisons",
  page: "/team",
  icon: faPeopleGroup
};

const draft = {
  title: 'Draftboard',
  subtitle: 'Future Draftboard',
  // desc: "Draftboard based on current standings w/ traded picks",
  page: "/draft",
  icon: faTableCells
};

const trades = {
  title: 'Trades',
  subtitle: 'Trade Statistics',
  // desc: "Scoring, H2H, Rosters over Time",
  page: "/trades",
  icon: faRightLeft
};

const standings = {
  title: 'Standings',
  subtitle: 'League Standings',
  // desc: "By Year, All-Time",
  page: "/standings",
  icon: faRankingStar
};

const trending = {
  title: 'Trending Players',
  subtitle: 'Top 50 Adds/Drops',
  // desc: "Add/Drops in Sleeper",
  page: "/trendingPlayers",
  icon: faSquareArrowUpRight
};

const cards = [lg, team, draft, trades, standings, trending]

function MenuCard(props) {
  return(
    <>
        <div className="">
            <ComplexGrid info={props.info}/>
        </div>
    </>
  )
}



// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(2),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));


function ResponsiveHomeGrid() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 16 }}>
        {Array.from(Array(6)).map((_, index) => (
          <Grid item xs={2} sm={4} md={2} key={index}>
            <MenuCard info={cards[index]}/>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default function Home(props) {

  // async function getLeaguePromise(lg){
  //   const currentUserInfo = await Auth.currentUserInfo().then(function(result) {return result.attributes[`custom:${lg}`]})
  //   console.log(`User in ${lg}? ${currentUserInfo}`)
  //   // const league       =  currentUserInfo.attributes[`custom:${lg}`]
  //   return currentUserInfo // 'yes'
  // }
  const league = useSelector((state) => state.league.value)
  // const league = selectLeague()
  console.log(`league=${league}`)

  const select = state => ({
    league: state.league.value
  })


  return (
    <>
      <div>
        <h1 className="font-bold text-2xl">Welcome {props.user.attributes.email}</h1>
        <h1> League: {select}</h1>

      </div>
      <LeagueSelect user={props.user.attributes.email} />
      <Divider />
      <div className="p-3">
        <ResponsiveHomeGrid />
      </div>
      <Divider />
      <div>
          <p>Things to Add:  Rank Chart over the year, Points Scored throughout year chart</p>
          {/* <Counter /> */}
        </div>
    </>
  );
}