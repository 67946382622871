import React, { useState } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useAxiosGet } from '../Hooks/HttpRequests';
import MuiTable from '../Components/MuiTable';
import HeatMap from "react-heatmap-grid";
import RankMap from "../Components/HeatMap";
import { Overview, BarChart, LineChart, ApexHeatMap } from "../Components/ReactApexChart";
import { Grid, scopedCssBaselineClasses } from "@mui/material";



export default function League() {
    let baseUrl = "https://sleepercdn.com/avatars/thumbs/"
    let muff = "cb70ea50e6ad6c633cfb8596b0b441b2"
    let smd = "95c1a952fda0af6e2497de08832c868f"
    let miltown = "0475831b08aa97a5f85e2fc835dfcef2"

    const [value, setValue] = useState("1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // if (props.smd === 'yes') {
    //     return (
    //     <div className="p-3">
    //             <h1 className="font-bold text-2xl">SuperMegaDynasty</h1>
    //             <a href="https://sleeper.com/leagues/785959210730311680/league">
    //             <img src={`${baseUrl}${smd}`} id="smd" alt="League Icon" />
    //             </a>
    //         </div>
    //     )
    // }

    return (
        <div className="mb-3">
            {/* <h1 className="font-bold text-2xl">This is the Team page</h1> */}
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                textColor="primary"
            >
                <Tab value="1" label="Lineup Averages" />
                <Tab value="2" label="Compare Teams" />
                <Tab value="3" label="Standings By Week" />
                {/* <Tab value="4" label="Four" /> */}
            </Tabs>
            <Divider />
            <div>
            {value === "1" && <LeagueAvg /> }
            {value === "2" && <CompareTree />}
            {value === "3" && <Three />}
            {/* {value === "4" && <Four />}
            {value === "5" && <Five />} */}
            </div>
        </div>
        // <div>
        //     <div className="p-3 min-w-min">
        //         <h1 className="font-bold text-2xl">SuperMegaDynasty</h1>
        //         {/* <a href="https://sleeper.com/leagues/785959210730311680/league"> */}
        //         <img src={`${baseUrl}${smd}`} id="smd" alt="League Icon" />
        //         {/* </a> */}
        //     </div>
        //     <div className="p-3">
        //         <h1 className="font-bold text-2xl">Muff Bowl</h1>
        //         {/* <a href="https://sleeper.com/leagues/789178227129188352/league"> */}
        //         <img src={`${baseUrl}${muff}`} id="muff" alt="League Icon" />
        //         {/* </a> */}
        //     </div>
        //     <div className="p-3">
        //         <h1 className="font-bold text-2xl">Miltown</h1>
        //         {/* <a href="https://sleeper.com/leagues/789690710231203840/league"> */}
        //         <img src={`${baseUrl}${miltown}`} id="miltown" alt="League Icon" />
        //         {/* </a> */}
        //     </div>
        // </div>
    );
}


function LeagueAvg(props) {

    const url = `https://supermegadynasty.com/smd/reports/league_avg/2023.json`
    console.log(url)
    const headCells = [
      // {
      //   id: 'id',
      //   numeric: false,
      //   disablePadding: false,
      //   label: 'ID',
      // },
      {
        id: 'username',
        numeric: false,
        disablePadding: false,
        label: 'Team',
      },
      {
        id: 'qb_avg',
        numeric: true,
        disablePadding: false,
        label: 'QB',
      },
      {
        id: 'rb_avg',
        numeric: true,
        disablePadding: false,
        label: 'RBs',
      },
    //   {
    //     id: 'rb2_avg',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'RB2',
    //   },
      {
        id: 'wr_avg',
        numeric: true,
        disablePadding: false,
        label: 'WRs',
      },
    //   {
    //     id: 'wr2_avg',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'WR2',
    //   },
      {
        id: 'te_avg',
        numeric: true,
        disablePadding: false,
        label: 'TE',
      },
      {
        id: 'wrt_avg',
        numeric: true,
        disablePadding: false,
        label: 'WRTs',
      },
    //   {
    //     id: 'wrt2_avg',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'WRT2',
    //   },
      {
        id: 'wrtq_avg',
        numeric: true,
        disablePadding: false,
        label: 'WRTQ',
      },
      {
        id: 'total_score',
        numeric: true,
        disablePadding: false,
        label: 'Avg Score',
      }
    ];

    const heatMapCells1 = [
        {
            id: 'username',
            numeric: false,
            disablePadding: false,
            label: 'Team',
        },
        {
          id: 'qb_avg',
          numeric: true,
          disablePadding: false,
          label: 'QB',
        },
        {
          id: 'rb1_avg',
          numeric: true,
          disablePadding: false,
          label: 'RB1',
        },
        {
          id: 'rb2_avg',
          numeric: true,
          disablePadding: false,
          label: 'RB2',
        },
        {
          id: 'wr1_avg',
          numeric: true,
          disablePadding: false,
          label: 'WR1',
        },
        {
          id: 'wr2_avg',
          numeric: true,
          disablePadding: false,
          label: 'WR2',
        },
        {
          id: 'te_avg',
          numeric: true,
          disablePadding: false,
          label: 'TE',
        },
        {
          id: 'wrt1_avg',
          numeric: true,
          disablePadding: false,
          label: 'WRT1',
        },
        {
          id: 'wrt2_avg',
          numeric: true,
          disablePadding: false,
          label: 'WRT2',
        },
        {
            id: 'wrtq_avg',
            numeric: true,
            disablePadding: false,
            label: 'WRTQ',
        },
    ];

    const headCells2 = [
        {
            id: 'username',
            numeric: false,
            disablePadding: false,
            label: 'Team',
        },
        {
            id: 'qb_rank',
            numeric: true,
            disablePadding: false,
            label: 'QB',
        },
        {
            id: 'rb_rank',
            numeric: true,
            disablePadding: false,
            label: 'RBs',
        },
        {
            id: 'wr_rank',
            numeric: true,
            disablePadding: false,
            label: 'WRs',
        },
        {
            id: 'te_rank',
            numeric: true,
            disablePadding: false,
            label: 'TE',
        },
        {
            id: 'wrt_rank',
            numeric: true,
            disablePadding: false,
            label: 'WRTs',
        },
        {
            id: 'wrtq_rank',
            numeric: true,
            disablePadding: false,
            label: 'WRTQ',
        },
        {
            id: 'total_score_rank',
            numeric: true,
            disablePadding: false,
            label: 'Scoring',
        }
    ];

    const heatMapCells2 = [
        {
            id: 'qb_rank',
            numeric: true,
            disablePadding: false,
            label: 'QB',
        },
        {
            id: 'rb_rank',
            numeric: true,
            disablePadding: false,
            label: 'RBs',
        },
        {
            id: 'wr_rank',
            numeric: true,
            disablePadding: false,
            label: 'WRs',
        },
        {
            id: 'te_rank',
            numeric: true,
            disablePadding: false,
            label: 'TE',
        },
        {
            id: 'wrt_rank',
            numeric: true,
            disablePadding: false,
            label: 'WRTs',
        },
        {
            id: 'wrtq_rank',
            numeric: true,
            disablePadding: false,
            label: 'WRTQ',
        },
        {
            id: 'total_score_rank',
            numeric: true,
            disablePadding: false,
            label: 'Scoring',
        }
    ];

    console.log('LINEUP AVG. PAGE')

    // TEST HEATMAP
    const yLabels = new Array(12).fill(0).map((_, i) => `${i+1}`);
    const xLabels = ["QB", "RB", "WR", "TE", "WRT", "WRTQ"];

    const data = new Array(yLabels.length)
    .fill(0)
    .map(() =>
        new Array(xLabels.length).fill(0).map(() => Math.floor(Math.random() * 100))
    );

    // const title = `Team ${props.team} Roster for Year: ${props.year} Week: ${props.week}`
    const title = `Lineup Position Averages 2023`

    return (
        <>
            <div className="md:flex flex-wrap">
                <div className="basis-1/2 pr-2">
                    <div>
                    {/* <h2>Team {props.team} Roster for Year: {props.year} Week: {props.week}</h2> */}
                    </div >
                    {/* {product.data.player_map.map(item => <div>{item.full_name}</div>)} */}
                    <MuiTable table='league_avg' headCells={headCells} url={url} title={title} page={30}/>
                </div>
                <div className="basis-1/2 ">
                    <div>
                    {/* <h2>Team {props.team} Roster for Year: {props.year} Week: {props.week}</h2> */}
                    </div >
                    {/* {product.data.player_map.map(item => <div>{item.full_name}</div>)} */}
                    <MuiTable table='league_rank_avg' headCells={headCells2} url={url} title="Lineup Position Ranks 2023" page={30}/>
                </div>
            </div>
            <div className="md:flex flex-wrap mb-3">
                <div className="basis-1/2 pr-2 ">
                    {/* <  HeatMap xLabels={xLabels} yLabels={yLabels} data={data} cellRender={(value) => value && `${value}`} />, */}
                    <MuiTable type='league_avg' headCells={heatMapCells1} url={url} title="Lineup by Roster Position Scoring Avg"/>
                </div>
                {/* <div className="basis-1/2  ">
                    <  HeatMap xLabels={xLabels} yLabels={yLabels} data={data} cellRender={(value) => value && `${value}`} />,
                    <RankMap type='rank' headCells={heatMapCells2} url={url}/>
                </div> */}
            </div>
            {/* <div className="md:flex flex-wrap mb-3">
                <div className=" basis-1/2  ">
                    <  HeatMap xLabels={xLabels} yLabels={yLabels} data={data} cellRender={(value) => value && `${value}`} />,
                    <ApexHeatMap title="Heat Map of Scoring" type='avg' headCells={heatMapCells1} url={url}/>
                </div>
                <div className="basis-1/2  ">
                    <  HeatMap xLabels={xLabels} yLabels={yLabels} data={data} cellRender={(value) => value && `${value}`} />,
                    <ApexHeatMap title="Heat Map of Ranking" type='rank' headCells={heatMapCells2} url={url}/>
                </div>
            </div> */}
        </>
    );
}

function CompareTree() {
    // const league = useSelector((state) => state.league.value)
    // const league = selectLeague()
    // console.log(`league=${league}`)

    return (
        <>
            <div className="">
                <ResponsiveLeagueGrid />
            </div>
            <Divider />
        </>
    );
}

function ResponsiveLeagueGrid() {
    return (
        <>
            <div className="md:flex flex-wrap">
                <div className="p-2 basis-1/3 ">
                    <div>
                    {/* <h2>Team {props.team} Roster for Year: {props.year} Week: {props.week}</h2> */}
                    </div >
                    {/* {product.data.player_map.map(item => <div>{item.full_name}</div>)} */}
                    <Overview />
                </div>
                <div className="p-2 basis-1/3 ">
                    <div>
                    {/* <h2>Team {props.team} Roster for Year: {props.year} Week: {props.week}</h2> */}
                    </div >
                    {/* {product.data.player_map.map(item => <div>{item.full_name}</div>)} */}
                    <Overview />
                </div>
                <div className="p-2 basis-1/3 ">
                    <div>
                    {/* <h2>Team {props.team} Roster for Year: {props.year} Week: {props.week}</h2> */}
                    </div >
                    {/* {product.data.player_map.map(item => <div>{item.full_name}</div>)} */}
                    {/* <LineChart /> */}
                </div>
            </div>
            <div className="md:flex flex-wrap mb-3">
                <div className="p-3 basis-1/2  ">
                    {/* <  HeatMap xLabels={xLabels} yLabels={yLabels} data={data} cellRender={(value) => value && `${value}`} />, */}
                    {/* <BarChart /> */}
                </div>
                <div className="p-3 basis-1/2  ">
                    {/* <  HeatMap xLabels={xLabels} yLabels={yLabels} data={data} cellRender={(value) => value && `${value}`} />, */}
                    {/* <BarChart /> */}
                </div>
            </div>
        </>


        // <Box sx={{ flexGrow: 1, flexBasis: '50%' }}>
        //     <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 2, md: 4 }}>
        //         <Grid item xs={1} sm={1} md={2} key='tree1'>
        //             <Overview />
        //         </Grid>
        //         <Grid item xs={1} sm={1} md={2} key='tree2'>
        //             <Overview />
        //         </Grid>
        //         <Grid item xs={1} sm={1} md={2} key='bar1'>
        //             <BarChart />
        //         </Grid>
        //         <Grid item xs={1} sm={1} md={2} key='bar2'>
        //             <BarChart />
        //         </Grid>

        //     </Grid>
        // </Box>
    );
}

function Three() {
    return (
        <div className="p-3">
            <h2>Standings by Week</h2>
            <LineChart />
        </div>
    );
}

function Four() {
    return (
    <div className="p-3">
        <h2>44444</h2>
    </div>
    );
}

function Five() {
    return (
        <div className="p-3">
            <h2>55555</h2>
        </div>
    );
}