import React from "react";

function TrendingPlayers() {
  return (
    <div className="p-3">
        <h1 className="p-3">Trending Up</h1>
        <iframe src="https://sleeper.app/embed/players/nfl/trending/add?lookback_hours=24&limit=25" width="350" height="500" allowtransparency="true" frameborder="0"></iframe>
        <h1 className="p-3">Trending Down</h1>
        <iframe src="https://sleeper.app/embed/players/nfl/trending/drop?lookback_hours=24&limit=25" width="350" height="500" allowtransparency="true" frameborder="0"></iframe>
    </div>
  );
}

export default TrendingPlayers();
