import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";


const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

export default function ComplexGrid({info}) {

    const { title, subtitle, desc, page, icon } = info
    return (
            <Paper
                sx={{
                    p: 1,
                    // margin: 'auto',
                    maxWidth: 500,
                    flexGrow: 1,
                    backgroundImage: 'none',
                    backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                }}
            >
            <Grid container spacing={2}>
                <Grid item>
                    <ButtonBase sx={{ width: 128, height: 128, color: "gold" }}>
                        <a href={page}>
                            <FontAwesomeIcon size="4x" icon={icon} />
                        </a>
                        {/* <FontAwesomeIcon size="5x" icon={props.icon} /> */}
                        {/* <Img alt="complex" src="android-chrome-192x192.png" /> */}
                    </ButtonBase>
                </Grid>
                <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                            <Typography gutterBottom variant="subtitle1" component="div">
                                {title}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                {subtitle}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {desc}
                            </Typography>
                        </Grid>
                        {/* <Grid item>
                            <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                Remove
                            </Typography>
                        </Grid> */}
                    </Grid>
                    {/* <Grid item>
                        <Typography variant="subtitle1" component="div">
                        $19.00
                        </Typography>
                    </Grid> */}
                </Grid>
            </Grid>
        </Paper>
    );
}