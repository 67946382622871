import { Divider } from "@mui/material";
import React from "react";

export default function Grid(props) {
  const teams = props.teams.map(obj => (
    <div
      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      className="board-owner rounded-lg text-center text-sm font-bold"
      height="20"
    >
      <h2 >{obj.name}</h2>
    </div>
  ));

  const cells = props.picks.map((pick, index) => (
    <div
      className={`board-square rounded-lg roster${pick.owner_id}`}
    >
      <div className="text-right pr-3" >
        {pick.round}.{(index+1) > 36 ? `${index-35}` : (index+1) > 24 ? `${index-23}` : (index+1) > 12 ? `${index-11}` : `${index+1}`}
      </div>
      <h2 className="text-center p-3">
        {pick.name}
      </h2>
    </div>
  )
  );
  //console.log(props.picks.slice(0,12))
  // console.log(props.picks.slice(12,24))
  // console.log(props.picks.slice(24,36))
  // console.log(props.picks.slice(36,48))



  return (
    <div>
      <div className="board rounded-lg">
        {teams}
        {/* {cells} */}
        <Row rd_picks={props.picks.slice(0,12)} />
        <Row rd_picks={props.picks.slice(12,24)} />
        <Row rd_picks={props.picks.slice(24,36)} />
        <Row rd_picks={props.picks.slice(36,48)} />
        <Divider />
      </div>
    </div>
  );
}

function Row(props) {

  return(
      <>
        {props.rd_picks.map((data, index) => (
          <div key={index} className={`board-square rounded-lg roster${data.owner_id}`}>
          {/* {console.log(data)} */}
          <div className="text-left pl-3 text-sm font-bold" >
            {data.round}.{index+1}
          </div>
          <h2 className="text-center text-xs font-bold">
            {data.name}
          </h2>
        </div>
        ))}
      </>
  )
}