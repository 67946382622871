import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useTransition, animated } from "react-spring";
import NavigationMenu from "./NavigationMenu";
import BasicSelect from "./BasicSelect";
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import { League } from "../features/league/League";

function Navigation(props) {
  const [showMenu, setShowMenu] = useState(false);

  const maskTransitions = useTransition(showMenu, {
    from: { position: "absolute", opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const menuTransitions = useTransition(showMenu, {
    from: { opacity: 0, transform: "translateX(-100%)" },
    enter: { opacity: 1, transform: "translateX(0%)" },
    leave: { opacity: 0, transform: "translateX(-100%)" },
  });

  // const Item = styled(Paper)(({ theme }) => ({
  //   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: 'center',
  //   color: theme.palette.text.secondary,
  // }));

  return (
    <>
      <FontAwesomeIcon icon={faBars} className="text-2xl align-middle	" onClick={() => setShowMenu(!showMenu)} />
      <span className="font-bold text-left" onClick={() => setShowMenu(!showMenu)}><a href="/"><img src="https://supermegadynasty.com/smd_200x75.png"/></a>
        </span>
    <nav>

      <Stack direction="row" spacing={2} className="items-center">
        {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> */}
        <span className="text-xs">{props.user}<br/><a href className="text-blue-500  underline" onClick={props.signOut}>Sign Out</a></span>
          
      </Stack>

      {/* <span className="text-2xl">
        <span className="p-3">Avatar</span>
        <span className="p-3">User</span>
        <span className="p-3"><BasicSelect /></span>
      </span> */}

      {maskTransitions(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="bg-black-t-50 fixed top-0 left-0 w-full h-full z-50"
              onClick={() => setShowMenu(!showMenu)}
            ></animated.div>
          )
      )}

      {menuTransitions(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="fixed bg-blue-900 top-0 left-0 w-200 h-full z-50 shadow p-3"
            >
              <NavigationMenu signOut={props.signOut} closeMenu={() => setShowMenu(false)} />
            </animated.div>
          )
      )}
    </nav>
    </>
  );
}

export default Navigation;
