import React, { Component, useState } from "react";
import Chart from "react-apexcharts";
import { useAxiosGet, useAxiosGetTotals, useAxiosGetTree } from '../Hooks/HttpRequests'
import axios from "axios";
import { smdTeams } from "../data/teamMap";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


export class LineChart extends Component {
    constructor(props) {
        super(props);

        // var url = 'http://my-json-server.typicode.com/apexcharts/apexcharts.js/yearly';

        // axios({
        // method: 'GET',
        // url: url,
        // }).then(function(response) {
        //     for (let i = 0; i < response.length; i++) {
        //         chart.updateSeries([{
        //             name: response.data[i].username,
        //             data: response.data
        //         }])
        //     }
        // })

        this.state = {
        options: {
            chart: {
                id: "basic-line",
                toolbar: {
                    show: false
                }
            },
            xaxis: {
                categories: Array.from({length: 12}, (_, i) => i + 1),
                // title: {
                //     text: "Weeks"
                // }
            },
            yaxis: {
                reversed: true,
                title: {
                    text: "Weekly Rank"
                }
            },
            tooltip: {
                theme: 'dark'
            },
            datalabels: {
                enabled: true
            },
            markers: {
                size: 0,
            }
        },
        series: [
            {
            name: "farina",
            data: [1, 3, 5, 3, 3, 3, 1, 1, 1, 1, 1, 1]
            },
            {
                name: "kurtz",
                data: [4, 2, 4, 4, 2, 1, 2, 2, 2, 2, 2, 2]
            }
        ]
        };
    }

    render() {
        return (
        <div className="md:flex max-w-prose">
            <div className="row">
            <div className="mixed-chart">
                <Chart
                options={this.state.options}
                series={this.state.series}
                type="line"
                width="500"
                />
            </div>
            </div>
        </div>
        );
    }
}

export class BarChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
        options: {
            chart: {
                id: "basic-bar",
                toolbar: {
                    show: false
                }
            },
            xaxis: {
                categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
            },
            tooltip: {
                theme: 'dark'
            },
        },
        series: [
            {
            name: "series-1",
            data: [30, 40, 45, 50, 49, 60, 70, 91]
            }
        ]
        };
    }

    render() {
        return (
        <div className="md:flex max-w-prose">
            <div className="row">
            <div className="mixed-chart">
                <Chart
                options={this.state.options}
                series={this.state.series}
                type="bar"
                width="500"
                />
            </div>
            </div>
        </div>
        );
    }
}

export class TreeMapClass extends Component {
  constructor(props) {
  super(props);

  // state = {
  //   expenses: [{id: '12345' ,name: 'Pizza', cost: '20'}],
  //   cost: "",
  //   name: ""
  // };

  // const { url } = this.props
  // let qbs = []
  // let wrs = []
  // let rbs = []
  // let tes = []
  // axios({
  //   method: 'GET',
  //   url: url,
  //   }).then(function(response) {
  //       qbs = response.data.filter( (row) => row.position.includes("QB"))
  //       for (let i = 0; i < qbs.length; i++) {
  //           let p_data = {
  //             x: qbs[i].full_name,
  //             y: qbs[i].starter_points
  //           }


  //           chart.updateSeries([{
  //               name: 'QBs',
  //               data: qbs..data
  //           }])
  //       }
  //       // rbs = response.data.filter( (row) => row.position.includes("RB"))
  //       // wrs = response.data.filter( (row) => row.position.includes("WR"))
  //       // tes = response.data.filter( (row) => row.position.includes("TE"))
  //       // console.log(qbs)
  //       // console.log(rbs)
  //       // console.log(wrs)
  //       // console.log(tes)
  //   })

  // if(rows.error){
  //   return (
  //     <div>
  //       <div className="bg-blue-300 mb-2 p-3">
  //             If you see this error, there was an issue loading the data - It will never be fixed, you might as well give up.
  //         </div>
  //         <div className="bg-red-300 p-3">
  //             There was an error please refresh or try again later, or don't IDGAF.
  //         </div>
  //     </div>
  //   )
  // }

  // let rows = product.data

  // console.log(rows)
  // const qbs = []


  // if(rows) {
  //     qbs = rows.filter( (row) => row.position.includes("QB"))
  //     console.log(rows)
  // }

  // console.log(qbs)


    this.state = {

        series: [
          {
            name: 'QBs',
            data: [
              {
                x: 'Aaron Rodgers',
                y: 10
              },
              {
                x: 'Tua Tagavailoa',
                y: 60
              },
              {
                x: 'Kirk Cousins',
                y: 41
              }
            ]
          },
          {
            name: 'RBs',
            data: [
              {
                x: 'Josh Jacobs',
                y: 10
              },
              {
                x: 'Jeff Wilson',
                y: 20
              }
            ]
          },
        {
          name: 'WRs',
          data: [
              {
                x: 'Jamarr',
                y: 51
              },
              {
                x: 'DK Metlcalf',
                y: 30
              },
              {
                x: 'MNO',
                y: 20
              },
              {
                x: 'CDE',
                y: 30
              }
            ]
          },
          {
            name: 'TE',
            data: [
              {
                x: 'Juwan Johnson',
                y: 10
              },
              {
                x: 'Zach Ertz',
                y: 20
              }
            ]
          },
        ],
        options: {
          legend: {
            show: false
          },
          chart: {
            height: 350,
            type: 'treemap',
            toolbar: {
                show: false
            }
          },
          title: {
            text: 'Multi-dimensional Treemap',
            align: 'center'
          },
          tooltip: {
            theme: 'dark'
        },
        },
      
      
      };
    }

  

    render() {
      return (
        

  <div id="chart" className="md:flex max-w-prose">
    <Chart
        options={this.state.options}
        series={this.state.series}
        type="treemap"
        // width="500"
        />
{/* <ReactApexChart options={this.state.options} series={this.state.series} type="treemap" height={350} /> */}
</div>

);
}
}

export function TreeMap(props){

  const url = props.url
  let title = props.title
  let response = useAxiosGetTree(props.url)

  if (response.data){
    // console.log(response.qbs)
  }

  let state = {

      series: [
        {
          name: 'QBs',
          data: response.qbs
        },
        {
          name: 'RBs',
          data: response.rbs
        },
      {
        name: 'WRs',
        data: response.wrs
        },
        {
          name: 'TE',
          data: response.tes
        },
      ],
      options: {
        legend: {
          show: false
        },
        chart: {
          // height: 'auto',
          // width: 'auto',
          type: 'treemap',
          toolbar: {
              show: false
          }
        },
        title: {
          text: props.title,
          align: 'center',
          style: {
            color: '#fff'
          },
        },
        tooltip: {
          theme: 'dark'
        },
        // responsive: [{
        //   breakpoint: undefined,
        //   options: {},
        // }]
      },
    };


  return (
  <div className="md:flex">
    <Chart
        options={state.options}
        series={state.series}
        type="treemap"
        width="700"
        />
</div>
  )
}

export function PieChart(props){

  let response = useAxiosGetTotals(props.url)

  if (response.data){
    console.log(response.data[0])
    const arr = response.data.reduce((a,v) =>  a = a + v, 0)


    let state = {
      series: response.data,
      options: {
        plotOptions: {
          pie: {
          customScale: 1
          }
        },
        labels: response.labels,
        legend: {
          show: false
        },
        chart: {
          // height: 'auto',
          // width: 'auto',
          type: 'pie',
          toolbar: {
              show: false
          }
        },
        title: {
          text: props.title,
          align: 'center',
          style: {
            color: '#fff'
          },
        },
        tooltip: {
          theme: 'dark'
        },
        // responsive: [{
        //   breakpoint: undefined,
        //   options: {},
        // }]
      },
      optionsRadial: {
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: "70%",
              background: "#000000",
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: "front",
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24
              }
            },
            track: {
              background: "#fff",
              strokeWidth: "67%",
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35
              }
            },

            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -20,
                show: true,
                color: "#888",
                fontSize: "13px"
              },
              value: {
                formatter: function (val) {
                  return Math.round(val * 100) / 100;
                },
                color: "#fff",
                fontSize: "30px",
                show: true
              }
            }
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#ABE5A1"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: "round"
        },
        labels: ["Total Pts"]
      },
      seriesRadial: [arr],
      optionsBar: {
        tooltip: {
          enabled: false,
        },
        chart: {
          stacked: false,
          stackType: "normal",
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        dataLabels: {
          dropShadow: {
            enabled: true
          },
        },
        stroke: {
          width: 0
        },
        xaxis: {
          categories: ["Pts. by Pos."],
          labels: {
            show: false
          },
          // max: 265,
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        fill: {
          opacity: 1,
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "vertical",
            shadeIntensity: 0.35,
            gradientToColors: undefined,
            inverseColors: false,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [90, 0, 100]
          }
        },

        legend: {
          position: "bottom",
          horizontalAlign: "right"
        }
      },
      seriesBar: [
        {
          name: response.labels[0],
          data: [ Math.round(response.data[0] * 100) / 100]
        },
        {
          name:  response.labels[1],
          data: [ Math.round(response.data[1] * 100) / 100]
        },
        {
          name:  response.labels[2],
          data: [ Math.round(response.data[2] * 100) / 100]
        },
        {
          name:  response.labels[3],
          data: [ Math.round(response.data[3] * 100) / 100]
        }
      ]
    };

    return (
    <div className="md:flex">
      <Chart
          options={state.options}
          series={state.series}
          type="pie"
          width="100%"
      />
      <div className="col radial-chart md:flex">
        <Chart
          options={state.optionsRadial}
          series={state.seriesRadial}
          type="radialBar"
          width="280"
        />
          </div>
          <div className="col percentage-chart md:flex">
            <Chart
              options={state.optionsBar}
              height={140}
              series={state.seriesBar}
              type="bar"
              width={500}
            />
          </div>
    </div>
    
    
    )
  }

}

export function Overview() {
  const teamOptions = Array.from({length: 12}, (_, i) => i + 1)
  const teams = smdTeams
  const [team, setTeam] = useState(1);
  const [teamName, setTeamName] = useState('caseyott');
  const title = `${teamName} Starting Points Breakdown`


  const [url, setUrl] = useState(`https://supermegadynasty.com/smd/reports/starting_pct/2023/${teamName}.json`)

  const handleTeam = (event) => {
    setTeam(event.target.value);
    for (var i=0; i < smdTeams.length; i++) {
      if (smdTeams[i]['roster_id'] == event.target.value) {
        setTeamName(smdTeams[i]['username']);
        setUrl(`https://supermegadynasty.com/smd/reports/starting_pct/2023/${smdTeams[i]['username']}.json`)
        console.log(url)
      }
    }
  };

  const teamMenuItems = []
  for (let i = 0; i < teamOptions.length; i++) {
    teamMenuItems.push(<MenuItem key={teams[i]['roster_id']} value={teams[i]['roster_id']}>{teams[i]['username']}</MenuItem>);
  }

  return (
    <div className="md:flex flex-wrap">
      <div className="">
        <Box >
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small" >
            <InputLabel id={'team'}>{'Team'}</InputLabel>
            <Select
            labelId={'team'}
            id={'team'}
            value={team}
            label={'Team'}
            onChange={handleTeam}
            >
            {teamMenuItems}
            </Select>
        </FormControl>
        </Box >
        <div className="md:flex flex-wrap mb-3">
          <div className="p-3 basis-1/2  ">
            < TreeMap url={url} title={title} />
          </div>
          <div className="p-3 basis-1/2  ">
            < PieChart url={url} title='Points by Position' />
          </div>
        </div>
      </div>
  </div>
  );
}

export function ApexHeatMap (props) {

  const url = props.url
  let title = props.title
  let response = useAxiosGetTree(props.url)
  let series = []
  let ranges =[]

  const colors = [
    '#429EBC',
    '#37C7BE',
    '#2CD295',
    '#21DD5E',
    '#16E819',
    '#4FF30B',
    '#99FE00',
    '#E4FF08',
    '#FFFF0F',
    '#FFCC17',
    '#FF7D1F',
    '#FF2626',
    '#FF2E2E',
  ]

  if (response.data){
    // console.log(response.data)
    if (props.type === 'rank'){
      for (let i = 0; i < response.data.length; i++) {
        // console.log(response.data[i]['username'])
        let p_data = {
          name: response.data[i]['username'],
          data:[response.data[i][`qb_${props.type}`],response.data[i][`rb_${props.type}`],response.data[i][`wr_${props.type}`],response.data[i][`te_${props.type}`],response.data[i][`wrt_${props.type}`],response.data[i][`wrtq_${props.type}`],response.data[i][`total_score_${props.type}`]]
        }
      series.push(p_data)
      }
      ranges = [
        {
          from: 1,
          to: 1,
          name: '1',
          color: colors[12],
          foreColor: "#000000"
        },
        {
          from: 2,
          to: 2,
          name: '2',
          color:colors[10],
          foreColor: "#000000"
          },
          {
            from: 3,
            to: 3,
            name: '3',
            color: colors[10],
            foreColor: "#000000"
            },
        {
          from: 4,
          to: 4,
          name: '4',
          color: colors[9],
          foreColor: "#000000"
        },
        {
          from: 5,
          to: 5,
          name: '5',
          color: colors[8],
          foreColor: "#000000"
          },
          {
            from: 6,
            to: 6,
            name: '6',
            color: colors[7],
            foreColor: "#000000"
          },
          {
            from: 7,
            to: 7,
            name: '7',
            color:colors[6],
            foreColor: "#000000"
            },
            {
              from: 8,
              to: 8,
              name: '8',
              color: colors[5],
              foreColor: "#000000"
              },
          {
            from: 9,
            to: 9,
            name: '9',
            color: colors[4],
            foreColor: "#000000"
          },
          {
            from: 10,
            to: 10,
            name: '10',
            color: colors[3],
            foreColor: "#000000"
          },
          {
            from: 11,
            to: 11,
            name: '11',
            color: colors[2],
            foreColor: "#000000"
            },
            {
              from: 12,
              to: 12,
              name: '12',
              color: colors[1],
              foreColor: "#000000"
              },
      ]
    }
    if (props.type === 'avg'){
      for (let i = 0; i < response.data.length; i++) {
        // console.log(response.data[i]['username'])
        let p_data = {
          name: response.data[i]['username'],
          data:[
            response.data[i][`qb_${props.type}`],
            response.data[i][`rb1_${props.type}`],
            response.data[i][`rb2_${props.type}`],
            response.data[i][`wr1_${props.type}`],
            response.data[i][`wr2_${props.type}`],
            response.data[i][`te_${props.type}`],
            response.data[i][`wrt1_${props.type}`],
            response.data[i][`wrt2_${props.type}`],
            response.data[i][`wrtq_${props.type}`],
          ]
        }
      series.push(p_data)
      }
      ranges = [
        {
          from: 0,
          to: 25,
          name: 'range',
          color: colors[12],
          foreColor: "#000000"
        },
        // {
        //   from: 17,
        //   to: 19,
        //   name: '17-19',
        //   color:colors[10],
        //   foreColor: "#000000"
        //   },
        //   {
        //     from: 15,
        //     to: 18,
        //     name: '15-18',
        //     color: colors[10],
        //     foreColor: "#000000"
        //     },
        // {
        //   from: 11,
        //   to: 14,
        //   name: '11-14',
        //   color: colors[9],
        //   foreColor: "#000000"
        // },
        // {
        //   from: 7,
        //   to: 14,
        //   name: '7-14',
        //   color: colors[8],
        //   foreColor: "#000000"
        //   },
          // {
          //   from: 5,
          //   to: 6,
          //   name: '6',
          //   color: colors[7],
          //   foreColor: "#000000"
          // },
          // {
          //   from: 3,
          //   to: 6,
          //   name: '7',
          //   color:colors[6],
          //   foreColor: "#000000"
          //   },
          //   {
          //     from: 1,
          //     to: 2,
          //     name: '8',
          //     color: colors[5],
          //     foreColor: "#000000"
          //     },
          // {
          //   from: -5,
          //   to: 0,
          //   name: '90',
          //   color: colors[4],
          //   foreColor: "#000000"
          // },
      ]
    }

    // console.log(series)
    let state = {
      series: series,
      options: {
        chart: {
            height: 350,
            type: 'heatmap',
            toolbar: {
                show: false
            },
        },
        legend: {
          labels: {
            colors: 'white',
          },
        },
        plotOptions: {
          heatmap: {
              shadeIntensity: 1,
              radius: 0,
              useFillColorAsStroke: true,
              colorScale: {
                // inverse: true,
                ranges: ranges,
            }
          }
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          width: 1
        },
        title: {
          text: title,
          style: {
            fontSize:  '14px',
            fontWeight:  'bold',
            // fontFamily:  undefined,
            color:  'white'
          },
        },
        tooltip: {
          theme: 'dark'
        }
      },
    };

    return (
      <div id="chart" className="md:flex max-w-prose">
      <Chart options={state.options} series={state.series} type="heatmap" height={'500'} width={'200%'} />
      </div>
    );
  }

  }

export class ApexHeatMapClass extends React.Component {
    constructor(props) {
      super(props);

      const colors = [
        '#429EBC',
        '#37C7BE',
        '#2CD295',
        '#21DD5E',
        '#16E819',
        '#4FF30B',
        '#99FE00',
        '#E4FF08',
        '#FFFF0F',
        '#FFCC17',
        '#FF7D1F',
        '#FF2626',
        '#FF2E2E',
      ]

      const rank = new Array(8).fill(0).map((_, i) => `${i+1}`);

      this.state = {
        series: [
            {
                name: "team-1",
                data: rank
            },
            {
                name: "series-2",
                data: rank
            },
            {
                name: "series-3",
                data: rank
            },
            {
                name: "series-4",
                data: rank
            },
            {
                name: "series-5",
                data: rank
            },
            {
                name: "series-6",
                data: rank
            },
        ],
        options: {
          chart: {
              height: 350,
              type: 'heatmap',
              toolbar: {
                  show: false
              },
          },
          legend: {
            labels: {
              colors: 'white',
            },
          },
          plotOptions: {
            heatmap: {
                shadeIntensity: 1,
                radius: 0,
                useFillColorAsStroke: true,
                colorScale: {
                  // inverse: true,
                  ranges: [
                    {
                      from: 1,
                      to: 1,
                      name: '1',
                      color: colors[12],
                      foreColor: "#000000"
                    },
                    {
                      from: 2,
                      to: 2,
                      name: '2',
                      color:colors[10],
                      foreColor: "#000000"
                      },
                      {
                        from: 3,
                        to: 3,
                        name: '3',
                        color: colors[10],
                        foreColor: "#000000"
                        },
                    {
                      from: 4,
                      to: 4,
                      name: '4',
                      color: colors[9],
                      foreColor: "#000000"
                    },
                    {
                      from: 5,
                      to: 5,
                      name: '5',
                      color: colors[8],
                      foreColor: "#000000"
                      },
                      {
                        from: 6,
                        to: 6,
                        name: '6',
                        color: colors[7],
                        foreColor: "#000000"
                      },
                      {
                        from: 7,
                        to: 7,
                        name: '7',
                        color:colors[6],
                        foreColor: "#000000"
                        },
                        {
                          from: 8,
                          to: 8,
                          name: '8',
                          color: colors[5],
                          foreColor: "#000000"
                          },
                      {
                        from: 9,
                        to: 9,
                        name: '9',
                        color: colors[4],
                        foreColor: "#000000"
                      },
                      {
                        from: 10,
                        to: 10,
                        name: '10',
                        color: colors[3],
                        foreColor: "#000000"
                        },
                        {
                          from: 11,
                          to: 11,
                          name: '11',
                          color: colors[2],
                          foreColor: "#000000"
                          },
                          {
                            from: 12,
                            to: 12,
                            name: '12',
                            color: colors[1],
                            foreColor: "#000000"
                            },
                  ],
              }
            }
          },
          dataLabels: {
            enabled: true
          },
          stroke: {
            width: 1
          },
          title: {
            text: 'HeatMap Chart with Color Range',
            style: {
              fontSize:  '14px',
              fontWeight:  'bold',
              // fontFamily:  undefined,
              color:  'white'
            },
          },
          tooltip: {
            theme: 'dark'
          }
        },
      };
    }


    render() {
      return (
  <div id="chart" className="md:flex max-w-prose">
<Chart options={this.state.options} series={this.state.series} type="heatmap" height={350} />
</div>


      );
    }
}


export class Sample extends Component {
  constructor(props) {
    super(props);

    this.updateCharts = this.updateCharts.bind(this);

    const test = [5, 5, 6, 1]
    const arr = test.reduce((a,v) =>  a = a + v, 0)

    this.state = {
      optionsMixedChart: {
        chart: {
          id: "basic-bar",
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            columnWidth: "50%"
          }
        },
        stroke: {
          width: [4, 0, 0]
        },
        xaxis: {
          categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
        },
        markers: {
          size: 6,
          strokeWidth: 3,
          fillOpacity: 0,
          strokeOpacity: 0,
          hover: {
            size: 8
          }
        },
        yaxis: {
          tickAmount: 5,
          min: 0,
          max: 100
        }
      },
      seriesMixedChart: [
        {
          name: "series-1",
          type: "line",
          data: [30, 40, 25, 50, 49, 21, 70, 51]
        },
        {
          name: "series-2",
          type: "column",
          data: [23, 12, 54, 61, 32, 56, 81, 19]
        },
        {
          name: "series-3",
          type: "column",
          data: [62, 12, 45, 55, 76, 41, 23, 43]
        }
      ],
      optionsRadial: {
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: "70%",
              background: "#fff",
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: "front",
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24
              }
            },
            track: {
              background: "#fff",
              strokeWidth: "67%",
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35
              }
            },

            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -20,
                show: true,
                color: "#888",
                fontSize: "13px"
              },
              value: {
                formatter: function (val) {
                  return val;
                },
                color: "#111",
                fontSize: "30px",
                show: true
              }
            }
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#ABE5A1"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: "round"
        },
        labels: ["Percent"]
      },
      seriesRadial: [arr],
      optionsBar: {
        chart: {
          stacked: true,
          stackType: "100%",
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        dataLabels: {
          dropShadow: {
            enabled: true
          }
        },
        stroke: {
          width: 0
        },
        xaxis: {
          categories: ["Fav Color"],
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        fill: {
          opacity: 1,
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "vertical",
            shadeIntensity: 0.35,
            gradientToColors: undefined,
            inverseColors: false,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [90, 0, 100]
          }
        },

        legend: {
          position: "bottom",
          horizontalAlign: "right"
        }
      },
      seriesBar: [
        {
          name: "blue",
          data: [32]
        },
        {
          name: "green",
          data: [41]
        },
        {
          name: "yellow",
          data: [12]
        },
        {
          name: "red",
          data: [65]
        }
      ]
    };
  }

  updateCharts() {
    const max = 90;
    const min = 30;
    const newMixedSeries = [];
    const newBarSeries = [];

    this.state.seriesMixedChart.forEach((s) => {
      const data = s.data.map(() => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      });
      newMixedSeries.push({ data: data, type: s.type });
    });

    this.state.seriesBar.forEach((s) => {
      const data = s.data.map(() => {
        return Math.floor(Math.random() * (180 - min + 1)) + min;
      });
      newBarSeries.push({ data, name: s.name });
    });

    this.setState({
      seriesMixedChart: newMixedSeries,
      seriesBar: newBarSeries,
      seriesRadial: [Math.floor(Math.random() * (90 - 50 + 1)) + 50]
    });
  }

  render() {
    return (
      <div className="app">
        <div className="row">
          <div className="col mixed-chart">
            <Chart
              options={this.state.optionsMixedChart}
              series={this.state.seriesMixedChart}
              type="line"
              width="500"
            />
          </div>

          <div className="col radial-chart">
            <Chart
              options={this.state.optionsRadial}
              series={this.state.seriesRadial}
              type="radialBar"
              width="280"
            />
          </div>
        </div>

        <div className="row ">
          <div className="col percentage-chart">
            <Chart
              options={this.state.optionsBar}
              height={140}
              series={this.state.seriesBar}
              type="bar"
              width={500}
            />
          </div>

          <p className="col">
            <button color="gray" onClick={this.updateCharts}>Update!</button>
          </p>
        </div>
      </div>
    );
  }
}

