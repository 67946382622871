import { configureStore } from '@reduxjs/toolkit'
import leagueSlice from '../features/league/leagueSlice';
// import counterReducer from '../features/counter/counterSlice'
import leagueReducer from '../features/league/leagueSlice'


export default configureStore({
    reducer: {
        // counter: counterReducer,
        // user: userReducer,
        league: leagueReducer,
    },
})

