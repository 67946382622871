import React from "react";
import {
  faTableCells,
  faCircleInfo,
  faRankingStar,
  faRightLeft,
  faHouse,
  faBackward,
  faFootball,
  faPeopleGroup,
  faSquareArrowUpRight,
  faSignOut
} from "@fortawesome/free-solid-svg-icons";
import NavLink, {MiniNavLink} from "./NavLink";
import LogoutIcon from '@mui/icons-material/Logout';

function NavigationMenu(props) {
  return (
    <div>
      <div className="font-bold py-3"><img src="https://supermegadynasty.com/smd_200x75.png"/></div>
      <ul>
        <NavLink name="Home" page='/' icon={faHouse} closeMenu={props.closeMenu}/>
        <NavLink name="League" page='/league' icon={faFootball} closeMenu={props.closeMenu}/>
        <NavLink name="Team" page='/team' icon={faPeopleGroup} closeMenu={props.closeMenu}/>
        <NavLink name="Trending Players" page='/trendingPlayers' icon={faSquareArrowUpRight } closeMenu={props.closeMenu}/>

        <NavLink name="Draftboard" page='/draft' icon={faTableCells} closeMenu={props.closeMenu}/>
        {/* <NavLink name="Past Drafts" page='/drafts_past' icon={faBackward} closeMenu={props.closeMenu}/> */}
        <NavLink name="Trades" page='/trades' icon={faRightLeft} closeMenu={props.closeMenu}/>
        <NavLink name="Standings" page='/standings' icon={faRankingStar} closeMenu={props.closeMenu}/>
        <NavLink name="About" page='/about' icon={faCircleInfo} closeMenu={props.closeMenu}/>
        <NavLink name="Sign Out" icon={faSignOut} closeMenu={props.signOut}/>

      </ul>
    </div>
  );
}
export default NavigationMenu;
